import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectLocationInitQuery = () => ({
  locations: [
    {
      name: types.string,
      organisation: {
        name: types.string,
      },
      users_location_admins_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      users_clinicians_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      tbsa_results_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    },
  ],
});

type X = Types.Unpacked<typeof _selectLocationInitQuery>;
export type Location = X['locations'][0];

export const selectLocationInitQuery = () =>
  gql`
    ${query(_selectLocationInitQuery())}
  `;

import React, { useCallback, useState } from 'react';
import { CustomStepper } from '@blumtechgroup/blum-react-core-components';
import { Types } from '@blumtechgroup/blum-ui-utils';
import { Location, validationErrors, ValidationErrors, LocationReducerAction, LicenseTypeAssignment } from '../..';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';
import StepLicenses from '../StepLicenses';
import { LicenseType } from '../../../Create/queries';

interface Props {
  completeLabel: string;
  location: Location;
  licenseTypes: LicenseType[];
  assignedLicenses: LicenseTypeAssignment[];
  dispatch: React.Dispatch<{ type: LocationReducerAction; value: any }>;
  handleSave: () => void;
}

const LocationSteps = ({ completeLabel, location, licenseTypes, assignedLicenses, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: Types.UnknownObject = {};
      if (_step === 0) {
        errorItems.name = !location.name ? validationErrors.name : false;
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [location, errors]
  );

  return (
    <CustomStepper
      step={step}
      isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
      completeLabel={completeLabel}
      setStep={setStep}
      validate={validate}
      handleComplete={handleSave}
      gridSize={8}
      stepperSteps={[
        {
          label: 'Details',
          component: <StepDetails location={location} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
        },
        {
          label: 'Licenses',
          component: <StepLicenses location={location} licenseTypes={licenseTypes} assignedLicenses={assignedLicenses} dispatch={dispatch} />,
        },
        {
          label: 'Summary',
          component: <StepReview location={location} licenseTypes={licenseTypes} setStep={setStep} />,
        },
      ]}
    />
  );
};

export default LocationSteps;

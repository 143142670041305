import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, InputBase } from '@mui/material';
import {
  CustomBackdrop,
  CustomDialog,
  CustomSortableTable,
  CustomViewToggle,
  CustomViewToggleViewType,
  DashboardLayout,
  GridActionCard,
  GridCard,
} from '@blumtechgroup/blum-react-core-components';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { selectLicenseTypesInitQuery, LicenseType, getNumberOfActiveLicensesQuery, GetNumberOfActiveLicensesVariables, GetNumberOfActiveLicensesResponse } from './queries';
import { Search as SearchIcon, Clear as ClearSearchIcon } from '@mui/icons-material/';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { SearchIconContainer, SearchPaper } from './styled';
import { deleteLicenseTypeMutation } from './mutations';

interface DeleteLicenseTypeState {
  licenseTypeId: string;
  licenseTypeName: string;
  confirmDialogOpen: boolean;
}

const ListLicenseTypes = (): React.ReactElement => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const [deleteDenied, setDeleteDenied] = useState<boolean>(false);
  const [viewMode, setViewMode] = useLocalStorage<CustomViewToggleViewType>('admin_license_types_list_view_mode', CustomViewToggleViewType.TABLE);

  const [deleteState, setDeleteState] = useState<DeleteLicenseTypeState>({
    licenseTypeId: '',
    licenseTypeName: '',
    confirmDialogOpen: false,
  });

  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const { data: licensesInitData, refetch: refetchLicenseTypes, loading } = useQuery(selectLicenseTypesInitQuery(), { fetchPolicy: 'no-cache' });
  const [deleteLicenseType] = useMutation(deleteLicenseTypeMutation);

  const [getNumberOfActiveLicenses] = useLazyQuery<GetNumberOfActiveLicensesResponse, GetNumberOfActiveLicensesVariables>(getNumberOfActiveLicensesQuery);

  useEffect(() => {
    if (licensesInitData) {
      setLicenseTypes(licensesInitData.license_types);
    }
  }, [licensesInitData]);

  const handleView = (licenseTypeId: string) => {
    navigate(`/admin/license-types/${licenseTypeId}`);
  };

  const handleEdit = (licenseTypeId: string) => {
    navigate(`/admin/license-types/${licenseTypeId}/edit`);
  };

  const handleCreate = () => {
    navigate('/admin/license-types/create');
  };

  const handleDelete = async () => {
    const { data: response } = await getNumberOfActiveLicenses({
      variables: {
        licenseTypeId: deleteState.licenseTypeId,
      },
    });

    const organisationCount = response ? response.organisation_license_type_assignments_aggregate.aggregate.count : 0;

    const notAllowed = organisationCount > 0;

    setDeleteDenied(notAllowed);
    if (notAllowed) {
      setDeleteState({
        licenseTypeId: '',
        licenseTypeName: '',
        confirmDialogOpen: false,
      });

      return;
    }

    await deleteLicenseType({ variables: { id: deleteState.licenseTypeId } });

    setDeleteState({
      licenseTypeId: '',
      licenseTypeName: '',
      confirmDialogOpen: false,
    });

    await refetchLicenseTypes();
  };

  const rows = licenseTypes.map((item) => {
    return {
      key: item.id,
      actions: [
        {
          label: 'View',
          onClick: () => handleView(item.id),
        },
        {
          label: 'Edit',
          onClick: () => handleEdit(item.id),
        },
        {
          label: 'Delete',
          destructive: true,
          onClick: () => setDeleteState({ licenseTypeId: item.id, licenseTypeName: item.name, confirmDialogOpen: true }),
        },
      ],
      columns: [
        {
          key: 'name',
          label: item.name,
        },
      ],
    };
  });

  return (
    <>
      <DashboardLayout breadcrumbs={[{ label: 'Admin', link: '/admin/home' }, { label: 'License Types' }]}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="right" alignItems="center">
              {viewMode === CustomViewToggleViewType.GRID && (
                <Grid item>
                  <SearchPaper variant="outlined">
                    <InputBase
                      sx={{ width: '100%' }}
                      placeholder="Search"
                      value={searchValue}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value || '')}
                      startAdornment={
                        <SearchIconContainer>
                          <SearchIcon />
                        </SearchIconContainer>
                      }
                      endAdornment={
                        <SearchIconContainer>
                          <ClearSearchIcon onClick={() => setSearchValue('')} />
                        </SearchIconContainer>
                      }
                      inputProps={{ 'aria-label': 'search list' }}
                    />
                  </SearchPaper>
                </Grid>
              )}
              <Grid item>
                <CustomViewToggle value={viewMode} handleChangeView={setViewMode} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {viewMode === CustomViewToggleViewType.TABLE ? (
              <CustomSortableTable
                searchable={['name']}
                title={'License Types'}
                counter={'filtered'}
                actions={[
                  {
                    key: 'create',
                    label: 'Create',
                    onClick: handleCreate,
                  },
                ]}
                headers={[
                  {
                    key: 'name',
                    label: 'Name',
                  },
                ]}
                rows={rows}
              />
            ) : (
              <Grid container spacing={2}>
                <GridActionCard gridSize={4} fill={true} onClick={handleCreate} />
                {licenseTypes
                  .filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
                  .map((item) => (
                    <GridCard fill={true} key={item.id} gridSize={4} title={item.name} handleEdit={() => handleEdit(item.id)} handleView={() => handleView(item.id)} />
                  ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </DashboardLayout>
      <CustomDialog
        open={deleteState.confirmDialogOpen}
        title={deleteState.licenseTypeName}
        subtitle={'Are you sure, you want to delete the License type?'}
        actions={[
          {
            label: 'Confirm',
            onClick: handleDelete,
          },
          {
            label: 'Cancel',
            onClick: () => setDeleteState((prev) => ({ ...prev, confirmDialogOpen: false })),
          },
        ]}
        handleClose={() => setDeleteState((prev) => ({ ...prev, confirmDialogOpen: false }))}
      />
      {loading && <CustomBackdrop label="Loading License types" />}
      <CustomDialog
        open={deleteDenied}
        title={'Delete license'}
        subtitle={'Unable to delete a selected license type.'}
        message={'Please, make sure that are organisations assigned to the selected license type.'}
        actions={[
          {
            label: 'Close',
            onClick: () => setDeleteDenied(false),
          },
        ]}
        handleClose={() => setDeleteDenied(false)}
      />
    </>
  );
};

export default ListLicenseTypes;

import React from 'react';
import { ClinicianUser, ClinicianUserReducerAction } from '../..';
import { LicenseType } from '../../../Create/queries';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface Props {
  user: ClinicianUser;
  licenseTypes: LicenseType[];
  dispatch: React.Dispatch<{ type: ClinicianUserReducerAction; value: any }>;
}

const StepLicense = ({ user, licenseTypes, dispatch }: Props): React.ReactElement => {
  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id="select-license-label">License Type</InputLabel>
      <Select
        labelId="select-license-label"
        id="select-license"
        value={user.license?.license_type_id}
        label="License Type"
        onChange={(e) => dispatch({ type: ClinicianUserReducerAction.LICENSE, value: e.target.value })}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {licenseTypes.map((item) => (
          <MenuItem value={item.license_type.id}>{item.license_type.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StepLicense;

import React, { useEffect, useReducer } from 'react';
import { CustomBackdrop } from '@blumtechgroup/blum-react-core-components';
import { organisationUserReducer, OrganisationUserReducerAction } from '../Common';
import Steps from '../Common/components/Steps';
import { useMutation, useQuery } from '@apollo/client';
import { editOrganisationUserInitQuery } from './queries';
import { updateOrganisationUserMutation } from './mutations';

interface Props {
  userId: string;
  handleFinish: () => void;
}

const EditOrganisationUsersComponent = ({ userId, handleFinish }: Props): React.ReactElement => {
  const [user, dispatchUser] = useReducer(organisationUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
  });

  const { data: editOrganisationUserInitData } = useQuery(editOrganisationUserInitQuery(userId), { fetchPolicy: 'no-cache' });
  const [updateOrganisationUser, { loading }] = useMutation(updateOrganisationUserMutation);

  useEffect(() => {
    if (editOrganisationUserInitData) {
      const { users_organisation_admin_by_pk } = editOrganisationUserInitData;
      dispatchUser({ type: OrganisationUserReducerAction.INIT, value: users_organisation_admin_by_pk });
    }
  }, [editOrganisationUserInitData]);

  const handleSave = async () => {
    const { email, ...obj } = user;

    const updateVariables = {
      pk_columns: {
        id: userId,
      },
      set: obj,
    };
    await updateOrganisationUser({ variables: updateVariables });

    handleFinish();
  };

  return (
    <>
      <Steps completeLabel="Save" user={user} isEdit={true} dispatch={dispatchUser} handleSave={handleSave} />
      {loading && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default EditOrganisationUsersComponent;

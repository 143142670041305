import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  HomeOutlined as HomeIcon,
  Home as HomeActiveIcon,
  HomeWorkOutlined as OrganisationIcon,
  HomeWork as OrganisationActiveIcon,
  GroupAddOutlined as UsersIcon,
  GroupAdd as UsersActiveIcon,
  ThreePOutlined as RequestedUsersIcon,
  ThreeP as RequestedUsersActiveIcon,
  SwitchAccountOutlined as CliniciansIcon,
  SwitchAccount as CliniciansActiveIcon,
  SettingsAccessibilityOutlined as TbsaResultsIcon,
  SettingsAccessibility as TbsaResultsActiveIcon,
} from '@mui/icons-material';

export function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} history={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export const listItems = [
  {
    name: 'admin',
    payload: {
      key: 'admin',
      title: 'Admin',
      items: [
        { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/admin/home' },
        { label: 'Organisations', icon: <OrganisationIcon />, iconActive: <OrganisationActiveIcon />, to: '/admin/organisations' },
        { label: 'Users', icon: <UsersIcon />, iconActive: <UsersActiveIcon />, to: '/admin/users' },
        { label: 'Requests', icon: <RequestedUsersIcon />, iconActive: <RequestedUsersActiveIcon />, to: '/admin/requested-users' },
      ],
    },
  },
  {
    name: 'organisation_admin',
    payload: {
      key: 'organisation_admin',
      title: 'Organisation',
      items: [
        { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/org/home' },
        { label: 'Hospitals', icon: <OrganisationIcon />, iconActive: <OrganisationActiveIcon />, to: '/org/locations' },
        { label: 'Users', icon: <UsersIcon />, iconActive: <UsersActiveIcon />, to: '/org/users' },
      ],
    },
  },
  {
    name: 'location_admin',
    payload: {
      key: 'location_admin',
      title: 'Hospital',
      items: [
        { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/loc/home' },
        { label: 'Users', icon: <UsersIcon />, iconActive: <UsersActiveIcon />, to: '/loc/users' },
        { label: 'Clinicians', icon: <CliniciansIcon />, iconActive: <CliniciansActiveIcon />, to: '/loc/clinicians' },
        { label: 'TBSA results', icon: <TbsaResultsIcon />, iconActive: <TbsaResultsActiveIcon />, to: '/loc/tbsa' },
      ],
    },
  },
];

import { types, query, params, rawString } from 'typed-graphqlify';
import { Types } from '@blumtechgroup/blum-ui-utils';
import { gql } from '@apollo/client';

const _selectLocationUserInitQuery = (userId: string) => ({
  users_location_admin_by_pk: params(
    { id: rawString(userId) },
    {
      first_name: types.string,
      last_name: types.string,
      organisation: {
        name: types.string,
      },
      location: {
        name: types.string,
      },
    }
  ),
});

type X = Types.Unpacked<typeof _selectLocationUserInitQuery>;
export type LocationUser = X['users_location_admin_by_pk'];

export const selectLocationUserInitQuery = (userId: string) =>
  gql`
    ${query(_selectLocationUserInitQuery(userId))}
  `;

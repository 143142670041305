import React from 'react';
import { ReadOnlyBlock } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { Location } from '../..';
import { LicenseType } from '../../../Create/queries';

interface Props {
  location: Location;
  licenseTypes: LicenseType[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ location, licenseTypes, setStep }: Props): React.ReactElement => (
  <ReadOnlyBlock
    items={[
      { label: 'Name', value: location.name },
      { label: 'Active', value: StringUtils.booleanToYesNo(location.active) },
      {
        label: 'Licenses',
        list: location.license_type_assignments.length
          ? location.license_type_assignments.map((item) => `${licenseTypes.find((i) => i.license_type_id === item.license_type_id)?.license_type.name} - ${item.count}`)
          : undefined,
        value: location.license_type_assignments.length ? undefined : 'No licenses assigned',
      },
    ]}
    handleEdit={() => setStep(0)}
  />
);

export default StepReview;

import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectLocationLicensesInitQuery = (locationId: string, licenseTypeId: string) => ({
  license_types_by_pk: params({ id: rawString(licenseTypeId) }, { name: types.string }),
  locations_by_pk: params(
    { id: rawString(locationId) },
    {
      name: types.string,
      organisation: {
        name: types.string,
      },
      license_type_assignments: params(
        {
          where: { license_type_id: { _eq: rawString(licenseTypeId) } },
        },
        [
          {
            count: types.number,
          },
        ]
      ),
      users_clinicians: params(
        {
          where: { license: { license_type_id: { _eq: rawString(licenseTypeId) } } },
        },
        [
          {
            id: types.string,
            first_name: types.string,
            last_name: types.string,
            email: types.string,
          },
        ]
      ),
    }
  ),
});

type X = Types.Unpacked<typeof _selectLocationLicensesInitQuery>;
export type LicenseType = X['license_types_by_pk'];
export type Location = X['locations_by_pk'];

export const selectLocationLicensesInitQuery = (organisationId: string, licenseTypeId: string) =>
  gql`
    ${query(_selectLocationLicensesInitQuery(organisationId, licenseTypeId))}
  `;

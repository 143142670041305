import { gql } from '@apollo/client';

export const insertClinicianUserMutation = gql`
  mutation ($object: users_clinician_insert_input!) {
    insert_users_clinician_one(object: $object) {
      id
    }
  }
`;

export interface InsertClinicianUserResponse {
  insert_users_clinician_one: {
    id: string;
  };
}

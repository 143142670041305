import React, { useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DashboardLayout, CustomBackdrop } from '@blumtechgroup/blum-react-core-components';
import { adminUserReducer, AdminUserReducerAction } from '../Common';
import Steps from '../Common/components/Steps';
import { useMutation, useQuery } from '@apollo/client';
import { editAdminUserInitQuery } from './queries';
import { updateAdminUserMutation } from './mutations';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';

type UrlParams = {
  admin_user_id: string;
};

const AdminEditUser = (): React.ReactElement => {
  const navigate = useNavigate();
  const { admin_user_id } = useParams<UrlParams>();
  const [user, dispatchUser] = useReducer(adminUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
  });

  const { data: editAdminUserInitQueryData } = useQuery(editAdminUserInitQuery(admin_user_id!), { fetchPolicy: 'no-cache' });
  const [updateAdminUser, { loading }] = useMutation(updateAdminUserMutation);

  useEffect(() => {
    if (editAdminUserInitQueryData) {
      const { users_admin_by_pk: usr } = editAdminUserInitQueryData;
      dispatchUser({ type: AdminUserReducerAction.INIT, value: usr });
    }
  }, [editAdminUserInitQueryData]);

  const handleSave = async () => {
    const updateVariables = {
      pk_columns: {
        id: admin_user_id,
      },
      set: user,
    };
    await updateAdminUser({ variables: updateVariables });

    handleFinish();
  };

  const handleFinish = () => {
    navigate(`/admin/users/${admin_user_id}`);
  };

  return (
    <>
      <DashboardLayout
        breadcrumbs={[
          { label: 'Admin', link: '/admin/home' },
          { label: 'Users', link: '/admin/users' },
          { label: StringUtils.stringFromArray([user.first_name, user.last_name]), link: `/admin/users/${admin_user_id}` },
          { label: 'Edit' },
        ]}>
        <Steps completeLabel="Save" user={user} isEdit={true} dispatch={dispatchUser} handleSave={handleSave} />
      </DashboardLayout>
      {loading && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default AdminEditUser;

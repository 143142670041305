import { styled } from '@mui/material/styles';
import { Typography, TextField } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  padding: theme.spacing(2),
  paddingBottom: 0,
  textAlign: 'center',
}));

export const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const Modal = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '0 0 auto',
  width: theme.spacing(56),
}));

export const LogoContainer = styled('div')(({ theme }) => ({
  width: theme.spacing(30),
  // marginBottom: theme.spacing(4),
  alignSelf: 'center',
}));

export const Form = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  // padding: theme.spacing(3),
  paddingBottom: 0,
}));

export const InputField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

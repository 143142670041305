import { gql } from '@apollo/client';

export const selectTbsaResultsQuery = gql`
  query ($filter: tbsa_results_bool_exp!) {
    tbsa_results(order_by: { created_at: desc }, where: $filter) {
      id
      tbsa
      fluids
      age
      weight
      created_at
      clinician {
        first_name
        last_name
        email
      }
    }
  }
`;

interface Clinician {
  first_name: string;
  last_name: string;
  email: string;
}

interface TbsaResult {
  id: string;
  tbsa: number;
  fluids: number;
  age: number;
  weight: number;
  created_at: string;
  clinician: Clinician;
}

export interface TbsaQueryResponse {
  tbsa_results: TbsaResult[];
}

import React, { useCallback } from 'react';
import { Grid, TextField } from '@mui/material';
import { LicenceType, ValidationErrors, validationErrors, LicenseTypeReducerAction } from '../..';
import { Types } from '@blumtechgroup/blum-ui-utils';

interface Props {
  licenseType: LicenceType;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: LicenseTypeReducerAction; value: any }>;
}

const StepDetails = ({ licenseType, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: Types.UnknownObject = {};
      if (field === 'name') {
        errorItems.name = !licenseType.name ? validationErrors.name : false;
      }
      setErrors({ ...errors, ...errorItems });
    },
    [licenseType, errors, setErrors]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          sx={{ width: '100%' }}
          error={errors.name as boolean}
          helperText={errors.name}
          value={licenseType.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LicenseTypeReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />
      </Grid>
    </Grid>
  );
};

export default StepDetails;

import React from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { selectLocationUsersQuery, Location } from './queries';
import ListTbsaResultsComponent from '../../../../../../components/Global/Organisations/Locations/Tbsa/List';

type UrlParams = {
  organisation_id: string;
  location_id: string;
};

const ListTbsaResults = (): React.ReactElement => {
  const { organisation_id, location_id } = useParams<UrlParams>();
  const { data: locationData } = useQuery<Location>(selectLocationUsersQuery, { fetchPolicy: 'no-cache', variables: { locationId: location_id } });

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Organisations', link: '/admin/organisations' },
        { label: locationData?.locations_by_pk.organisation.name ?? '', link: `/admin/organisations/${organisation_id}` },
        { label: 'Hospitals', link: `/admin/organisations/${organisation_id}/locations` },
        { label: locationData?.locations_by_pk.name ?? '', link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
        { label: 'TBSA results' },
      ]}>
      <ListTbsaResultsComponent organisationId={organisation_id} locationId={location_id} />
    </DashboardLayout>
  );
};

export default ListTbsaResults;

import { params, query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

export const _homeInitQuery = () => ({
  organisations_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  users_admin_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  license_types_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  requested_users_aggregate: params(
    { where: { is_approved: { _is_null: true } } },
    {
      aggregate: {
        count: types.number,
      },
    }
  ),
});

export type HomePageState = Types.Unpacked<typeof _homeInitQuery>;

export const homeInitQuery = () =>
  gql`
    ${query(_homeInitQuery())}
  `;

import { StringUtils } from '@blumtechgroup/blum-ui-utils';

export interface LocationUser {
  first_name: string;
  last_name: string;
  email: string;
  active: boolean;
}

export interface ValidationErrors {
  first_name: string | boolean;
  last_name: string | boolean;
  email: string | boolean;
}

export const validationErrors: ValidationErrors = {
  first_name: 'Please provide a first name',
  last_name: 'Please provide a last name',
  email: 'Please provide a valid email address',
};

export enum LocationUserReducerAction {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  ACTIVE,
  INIT,
}

export const locationUserReducer = (state: LocationUser, action: { type: LocationUserReducerAction; value: any }): LocationUser => {
  switch (action.type) {
    case LocationUserReducerAction.FIRST_NAME:
      return { ...state, first_name: StringUtils.capitaliseFirst(action.value) };
    case LocationUserReducerAction.LAST_NAME:
      return { ...state, last_name: StringUtils.capitaliseFirst(action.value) };
    case LocationUserReducerAction.EMAIL:
      return { ...state, email: action.value?.toLowerCase() };
    case LocationUserReducerAction.ACTIVE:
      return { ...state, active: action.value };
    case LocationUserReducerAction.INIT:
      const { first_name, last_name, email, active } = action.value;
      const obj = {
        first_name,
        last_name,
        email,
        active,
      };
      return { ...(obj as LocationUser) };
    default:
      throw new Error();
  }
};

import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectOrganisationLicensesInitQuery = (organisationId: string, licenseTypeId: string) => ({
  license_types_by_pk: params({ id: rawString(licenseTypeId) }, { name: types.string }),
  organisations_by_pk: params(
    { id: rawString(organisationId) },
    {
      name: types.string,
      license_type_assignments: params(
        {
          where: { license_type_id: { _eq: rawString(licenseTypeId) } },
        },
        [
          {
            count: types.number,
          },
        ]
      ),
      locations: [
        {
          id: types.string,
          name: types.string,
          license_type_assignments: params(
            {
              where: { license_type_id: { _eq: rawString(licenseTypeId) } },
            },
            [
              {
                count: types.number,
              },
            ]
          ),
        },
      ],
    }
  ),
});

type X = Types.Unpacked<typeof _selectOrganisationLicensesInitQuery>;
export type Organisation = X['organisations_by_pk'];
export type LicenseType = X['license_types_by_pk'];

export const selectOrganisationLicensesInitQuery = (organisationId: string, licenseTypeId: string) =>
  gql`
    ${query(_selectOrganisationLicensesInitQuery(organisationId, licenseTypeId))}
  `;

import React from 'react';
import { ReadOnlyBlock } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { LocationUser } from '../..';

interface Props {
  user: LocationUser;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ user, setStep }: Props): React.ReactElement => {
  return (
    <ReadOnlyBlock
      items={[
        { label: 'Full Name', value: StringUtils.stringFromArray([user.first_name, user.last_name]) },
        { label: 'Email', value: user.email },
        { label: 'Active', value: StringUtils.booleanToYesNo(user.active) },
      ]}
      handleEdit={() => setStep(0)}
    />
  );
};

export default StepReview;

import { types, query, params, rawString } from 'typed-graphqlify';
import { Types } from '@blumtechgroup/blum-ui-utils';
import { gql } from '@apollo/client';

const _selectLocationUsersInitQuery = (locationId?: string) => ({
  locations: params(
    {
      offset: 0,
      ...(locationId
        ? {
            where: {
              id: { _eq: rawString(locationId) },
            },
          }
        : {}),
    },
    [
      {
        name: types.string,
        organisation: {
          name: types.string,
        },
        users_location_admins: [
          {
            id: types.string,
            first_name: types.string,
            last_name: types.string,
            email: types.string,
            active: types.boolean,
          },
        ],
      },
    ]
  ),
});

type X = Types.Unpacked<typeof _selectLocationUsersInitQuery>;
export type Location = X['locations'][0];

export const selectLocationUsersInitQuery = (locationId?: string) =>
  gql`
    ${query(_selectLocationUsersInitQuery(locationId))}
  `;

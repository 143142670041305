import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const Root = styled('div')(({ theme }) => ({
  ddisplay: 'flex',
  flexDirection: 'column',
  flex: '0 0 auto',
  width: theme.spacing(48),
}));

export const LogoContainer = styled('div')(({ theme }) => ({
  width: theme.spacing(30),
  alignSelf: 'center',
}));

export const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const Info = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

export const InputField = styled(TextField)(({ theme }) => ({
  dimarginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  '&:first-of-type': {
    marginTop: 0,
  },
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const Form = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 0,
}));

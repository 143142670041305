import React, { useState, useEffect, useReducer } from 'react';
import { CustomBackdrop, CustomDialog } from '@blumtechgroup/blum-react-core-components';
import { clinicianUserReducer, ClinicianUserReducerAction } from '../Common';
import Steps from '../Common/components/Steps';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { editClinicianUserInitQuery, getActiveUsersForLocationQuery, getActiveUsersQuery, GetActiveUsersResponse, GetActiveUsersVariables, Location } from './queries';
import { updateClinicianUserMutation } from './mutations';

interface Props {
  organisationId?: string;
  locationId?: string;
  clinicianId: string;
  handleFinish: () => void;
}

const EditClinicianUsersComponent = ({ organisationId, locationId, clinicianId, handleFinish }: Props): React.ReactElement => {
  const [user, dispatchUser] = useReducer(clinicianUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
    license: undefined,
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [quotaExceeded, setQuotaExceeded] = useState<boolean>(false);

  const [location, setLocation] = useState<Location>({
    license_type_assignments: [],
    clinician_license_type_assignments: [],
  });

  const { data: editClinicianUserInitData } = useQuery(editClinicianUserInitQuery(clinicianId), { fetchPolicy: 'no-cache' });
  const [updateClinicianUser] = useMutation(updateClinicianUserMutation);

  const [getActiveUsers] = useLazyQuery<GetActiveUsersResponse, GetActiveUsersVariables>(locationId ? getActiveUsersForLocationQuery : getActiveUsersQuery);

  useEffect(() => {
    if (editClinicianUserInitData) {
      const { users_clinician_by_pk } = editClinicianUserInitData;
      dispatchUser({ type: ClinicianUserReducerAction.INIT, value: users_clinician_by_pk });
      setLocation(users_clinician_by_pk.location);
    }
  }, [editClinicianUserInitData]);

  const handleSave = async () => {
    setSaving(true);

    const { license, email, ...obj } = user;

    if (!!license) {
      const { data: response } = await getActiveUsers({
        variables: {
          clinicianId: clinicianId,
          locationId: locationId,
          licenseTypeId: license.license_type_id,
        },
      });

      const activeUsers = response?.clinician_license_type_assignments_aggregate.aggregate.count ?? 0;
      const usersQuota = response?.location_license_type_assignments?.length ? response.location_license_type_assignments[0].count : 0;

      const licenseAllowed = activeUsers < usersQuota;
      setQuotaExceeded(!licenseAllowed);
      if (!licenseAllowed) {
        setSaving(false);
        return;
      }
    }

    const insertLicenses = !license
      ? []
      : [
          {
            organisation_id: organisationId,
            location_id: locationId,
            clinician_id: clinicianId,
            license_type_id: license.license_type_id,
          },
        ];

    const updateVariables = {
      pk_columns: {
        id: clinicianId,
      },
      set: obj,
      insert_licenses: insertLicenses,
      delete_licenses: !license ? [clinicianId] : [],
    };

    await updateClinicianUser({ variables: updateVariables });

    setSaving(false);
    handleFinish();
  };

  const availableLicenseTypes = location.license_type_assignments.filter((item) => {
    const assigned = location.clinician_license_type_assignments.filter((i) => i.license_type_id === item.license_type.id && i.clinician_id !== clinicianId).length;

    return assigned < item.count;
  });

  return (
    <>
      <Steps completeLabel="Save" licenseTypes={availableLicenseTypes} isEdit={true} user={user} dispatch={dispatchUser} handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
      <CustomDialog
        open={quotaExceeded}
        title={'Quota exceeded'}
        subtitle={'You reach a maximum number of active users per selected license type.'}
        message={'Please get in touch with the administrator to help you increase the number of active users per selected license.'}
        actions={[
          {
            label: 'Close',
            onClick: () => setQuotaExceeded(false),
          },
        ]}
        handleClose={() => setQuotaExceeded(false)}
      />
    </>
  );
};

export default EditClinicianUsersComponent;

import React from 'react';
import { Amplify, Auth } from 'aws-amplify';
import useLocalStorage from '../../hooks/useLocalStorage';

Amplify.configure({
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT,
});

interface CognitoContextItems {
  Auth: typeof Auth;
  token: string | null;
  setToken: (token: string | null) => void;
  removeToken: () => void;
}

export const CognitoContext = React.createContext<CognitoContextItems | null>(null);

type Props = { children: React.ReactNode };

const CognitoProvider = ({ children }: Props): React.ReactElement => {
  const [token, setToken, removeToken] = useLocalStorage<string | null>('token', null);

  const context: CognitoContextItems = {
    Auth,
    token,
    setToken,
    removeToken,
  };
  return <CognitoContext.Provider value={context}>{children}</CognitoContext.Provider>;
};

export default CognitoProvider;

import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { CenteredLayout } from '@blumtechgroup/blum-react-core-components';
import { ValidatorsUtils } from '@blumtechgroup/blum-ui-utils';
import { AuthContext } from '../../../components/AuthProvider';
import { Logo } from '../../../components/Logos';
import { Root, Content, Modal, LogoContainer, ProgressContainer, ButtonContainer, Password } from './styled';

const validateEmailAddress = ValidatorsUtils.validateEmailAddress;
const validatePassword = ValidatorsUtils.validatePassword;

interface CustomSignInError {
  signIn?: string;
  emailAddress?: string;
  password?: string;
}

const SignIn = () => {
  let navigate = useNavigate();
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }
  const { loggedInUser, userRole, signIn, setLoggedInUser } = authContext;

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<CustomSignInError>({});
  const [signingIn, setSigningIn] = useState<boolean>(false);
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [forgetPassword, setForgetPassword] = useState<boolean>(false);

  const getRoutePrefix = (role: string): string => {
    if (role === 'organisation_admin') {
      return 'org';
    }

    if (role === 'location_admin') {
      return 'loc';
    }

    if (role === 'admin') {
      return 'admin';
    }

    return role;
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && userRole && loggedInUser) {
      let role: string = userRole;
      return navigate(`/${getRoutePrefix(role)}/home`);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser, userRole]);

  const handleSignIn = () => {
    let error = false;
    setErrors({});
    if (!emailAddress) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide an email address' }));
      error = true;
    } else if (!validateEmailAddress(emailAddress)) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide a valid email address' }));
      error = true;
    }
    if (!password) {
      setErrors((e) => ({ ...e, password: 'Please provide a password' }));
      error = true;
    } else if (!validatePassword(password)) {
      setErrors((e) => ({ ...e, password: 'Passwords must be at least 8 characters long and contain at least 1 lower case letter, 1 upper case letter, and a number' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = useCallback(async () => {
    setSigningIn(true);
    try {
      const signInRequest = await signIn(emailAddress, password);
      if (signInRequest) {
        const { challengeName } = signInRequest;
        if (challengeName === 'NEW_PASSWORD_REQUIRED') {
          setLoggedInUser(signInRequest);
          navigate('/initial-sign-in');
        } else if (challengeName === 'NEW_PASSWORD') {
          navigate('/initial-sign-in');
        } else if (challengeName === 'RESET_REQUIRED') {
          navigate('/reset-password');
        } else {
          setSigningIn(false);
        }
      }
    } catch (err: any) {
      setSigningIn(false);
      if (err.code === 'PasswordResetRequiredException') {
        setResetPassword(true);
      }
      if (err.code === 'UserNotFoundException') {
        setErrors((e) => ({ ...e, signIn: 'Incorrect username and password' }));
      } else {
        setErrors((e) => ({ ...e, signIn: err.message }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddress, password, setLoggedInUser, signIn]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleContinue();
    }
  };

  const handleResetPassword = () => {
    navigate(`/reset-password?e=${btoa(emailAddress)}`);
  };

  const handleForgotPassword = () => {
    setForgetPassword(true);
  };

  const handleResetPasswordSubmit = async () => {
    let error = false;
    if (!emailAddress) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide an email address' }));
      error = true;
    } else if (!validateEmailAddress(emailAddress)) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide a valid email address' }));
      error = true;
    }
    if (!error) {
      setSigningIn(true);
      try {
        await Auth.forgotPassword(emailAddress);
        setSigningIn(false);
        setTimeout(() => {
          navigate('/reset-password');
        }, 1500);
      } catch (err: any) {
        setSigningIn(false);
        setErrors((e) => ({ ...e, signIn: err.message || 'Unknown error' }));
      }
    }
  };

  const getContent = () => (
    <>
      <Root>
        <TextField
          data-qa="email-textfield"
          required
          label="Email address"
          type="email"
          variant="outlined"
          error={errors.emailAddress !== undefined}
          helperText={errors.emailAddress}
          value={emailAddress}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmailAddress(event.target.value || '')}
        />
        {!forgetPassword && !resetPassword && (
          <Password
            data-qa="password-textfield"
            required
            label="Password"
            type="password"
            variant="outlined"
            error={errors.password !== undefined}
            helperText={errors.password}
            value={password}
            onKeyDown={handleKeyPress}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value || '')}
          />
        )}
      </Root>
      {errors.signIn && <Typography align="center">{errors.signIn}</Typography>}
      {forgetPassword && !emailAddress && <Typography align="center">Please provide email address</Typography>}
      <ButtonContainer>
        {!signingIn && !resetPassword && !forgetPassword && (
          <>
            <Button data-qa="signin-button" variant="contained" color="primary" onClick={handleSignIn}>
              SIGN IN
            </Button>
            <Button sx={{ textTransform: 'none' }} variant="text" color="secondary" onClick={handleForgotPassword}>
              Forgot password
            </Button>
          </>
        )}
        {/* {!signingIn && !resetPassword && !forgetPassword && <Button data-qa="create-account-button" variant="contained" color="secondary" onClick={handleSignIn}>Create account</Button>} */}
        {!signingIn && forgetPassword && !resetPassword && (
          <Button data-qa="request-new-password" variant="contained" color="primary" onClick={handleResetPasswordSubmit}>
            Request New Password
          </Button>
        )}
        {!signingIn && resetPassword && (
          <Button data-qa="reset-password-button" variant="contained" color="primary" onClick={handleResetPassword}>
            RESET PASSWORD
          </Button>
        )}
        {signingIn && !forgetPassword && (
          <ProgressContainer>
            <CircularProgress />
            <Typography sx={{ marginTop: (theme) => theme.spacing(1) }} variant="subtitle2">
              Signing in
            </Typography>
          </ProgressContainer>
        )}
      </ButtonContainer>
    </>
  );

  return (
    <CenteredLayout>
      <Modal>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Content>{getContent()}</Content>
      </Modal>
    </CenteredLayout>
  );
};

export default SignIn;

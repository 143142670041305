import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { CustomSortableTable, DashboardLayout, ReadOnlyBlock } from '@blumtechgroup/blum-react-core-components';
import { useQuery } from '@apollo/client';
import { selectOrganisationLicensesInitQuery, Organisation, LicenseType } from './queries';

type UrlParams = {
  license_type_id: string;
  organisation_id: string;
};

interface ViewOrganisationLicensesPageState {
  organisation: Organisation;
  licenseType: LicenseType;
}

const ViewOrganisationLicenses = (): React.ReactElement => {
  const navigate = useNavigate();

  const { license_type_id, organisation_id } = useParams<UrlParams>();

  const [pageState, setPageState] = useState<ViewOrganisationLicensesPageState>({
    organisation: {
      name: '',
      license_type_assignments: [],
      locations: [],
    },
    licenseType: {
      name: '',
    },
  });

  const { data: organisationLicensesData } = useQuery(selectOrganisationLicensesInitQuery(organisation_id!, license_type_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (organisationLicensesData) {
      setPageState({
        organisation: organisationLicensesData.organisations_by_pk,
        licenseType: organisationLicensesData.license_types_by_pk,
      });
    }
  }, [organisationLicensesData]);

  const handleView = (locationId: string) => {
    navigate(`/admin/license-types/${license_type_id}/organisations/${organisation_id}/locations/${locationId}`);
  };

  const handleEdit = () => {
    navigate(`/admin/license-types/${license_type_id}/edit`);
  };

  const rows = pageState.organisation.locations.map((item) => {
    return {
      key: item.id,
      actions: [
        {
          label: 'View',
          onClick: () => handleView(item.id),
        },
      ],
      columns: [
        {
          key: 'name',
          label: item.name,
        },
        {
          key: 'count',
          label: item.license_type_assignments.length ? item.license_type_assignments[0].count : 0,
        },
      ],
    };
  });

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'License Types', link: '/admin/license-types' },
        { label: pageState.licenseType.name, link: `/admin/license-types/${license_type_id}` },
        { label: pageState.organisation.name },
      ]}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReadOnlyBlock
            gridSize={4}
            items={[
              {
                label: 'Name',
                components: [
                  <Typography variant="h5" component="span">
                    {pageState.licenseType.name}
                  </Typography>,
                ],
              },
              {
                label: 'Available licenses',
                value: pageState.organisation.license_type_assignments.length ? pageState.organisation.license_type_assignments[0].count.toString() : '0',
              },
            ]}
            handleEdit={handleEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSortableTable
            searchable={['name']}
            title={'Hospitals'}
            counter={'filtered'}
            headers={[
              {
                key: 'name',
                label: 'Name',
              },
              {
                key: 'count',
                label: 'Count',
              },
            ]}
            rows={rows}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ViewOrganisationLicenses;

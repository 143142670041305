import React, { useState, useEffect } from 'react';
import { CustomSortableTable, DashboardLayout, ReadOnlyBlock } from '@blumtechgroup/blum-react-core-components';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { selectLicenseTypeInitQuery, LicenseType } from './queries';

type UrlParams = {
  license_type_id: string;
};

const ViewLicenseTypes = (): React.ReactElement => {
  const navigate = useNavigate();
  const { license_type_id } = useParams<UrlParams>();

  const [licenseType, setLicenseType] = useState<LicenseType>({
    name: '',
    organisation_assignments: [],
  });

  const { data: licenseTypeInitQuery } = useQuery(selectLicenseTypeInitQuery(license_type_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (licenseTypeInitQuery) {
      setLicenseType(licenseTypeInitQuery.license_types_by_pk);
    }
  }, [licenseTypeInitQuery]);

  const handleEdit = () => {
    navigate(`/admin/license-types/${license_type_id}/edit`);
  };

  const handleViewOrganisation = (organisationId: string) => {
    navigate(`/admin/license-types/${license_type_id}/organisations/${organisationId}`);
  };

  const handleEditOrganisation = (organisationId: string) => {
    navigate(`/admin/organisations/${organisationId}/edit`);
  };

  const rows = licenseType.organisation_assignments.map((item) => {
    return {
      key: item.organisation.id,
      actions: [
        {
          label: 'View',
          onClick: () => handleViewOrganisation(item.organisation.id),
        },
        {
          label: 'Edit',
          onClick: () => handleEditOrganisation(item.organisation.id),
        },
      ],
      columns: [
        {
          key: 'name',
          label: item.organisation.name,
        },
        {
          key: 'count',
          label: item.count,
        },
      ],
    };
  });

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Admin', link: '/admin/home' }, { label: 'License Types', link: '/admin/license-types' }, { label: `${licenseType.name}` }]}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReadOnlyBlock
            items={[
              {
                label: 'Name',
                components: [
                  <Typography variant="h5" component="span">
                    {licenseType.name}
                  </Typography>,
                ],
              },
            ]}
            handleEdit={handleEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSortableTable
            searchable={['name']}
            title={'Organisations'}
            counter={'filtered'}
            actions={[]}
            headers={[
              {
                key: 'name',
                label: 'Name',
              },
              {
                key: 'count',
                label: 'Count',
              },
            ]}
            rows={rows}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ViewLicenseTypes;

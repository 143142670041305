import React, { useCallback, useState } from 'react';
import { CustomStepper } from '@blumtechgroup/blum-react-core-components';
import { AdminUser, validationErrors, ValidationErrors, AdminUserReducerAction } from '../..';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';
import { Types } from '@blumtechgroup/blum-ui-utils';

interface Props {
  completeLabel: string;
  user: AdminUser;
  isEdit: boolean;
  dispatch: React.Dispatch<{ type: AdminUserReducerAction; value: any }>;
  handleSave: () => void;
}

const LabUserSteps = ({ completeLabel, user, isEdit, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    first_name: false,
    last_name: false,
    email: false,
    email_invalid: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: Types.UnknownObject = {};
      if (_step === 0) {
        errorItems.first_name = !user.first_name ? validationErrors.first_name : false;
        errorItems.last_name = !user.last_name ? validationErrors.last_name : false;
        errorItems.email = !user.email ? validationErrors.email : false;
        errorItems.email_invalid = !user.email ? validationErrors.email : false;
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [user, errors]
  );

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}
        stepperSteps={[
          {
            label: 'Details',
            component: <StepDetails user={user} errors={errors} isEdit={isEdit} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Summary',
            component: <StepReview user={user} setStep={setStep} />,
          },
        ]}
      />
    </>
  );
};

export default LabUserSteps;

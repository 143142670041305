import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectOrganisationInitQuery = (organisationId: string) => ({
  organisations_by_pk: params(
    { id: rawString(organisationId) },
    {
      name: types.string,
      users_organisation_admins_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      locations_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    }
  ),
});

type X = Types.Unpacked<typeof _selectOrganisationInitQuery>;
export type Organisation = X['organisations_by_pk'];

export const selectOrganisationInitQuery = (organisationId: string) =>
  gql`
    ${query(_selectOrganisationInitQuery(organisationId))}
  `;

import React from 'react';
import { ReadOnlyBlock } from '@blumtechgroup/blum-react-core-components';
import { AdminUser } from '../..';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';

interface Props {
  user: AdminUser;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ user, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Name', value: StringUtils.stringFromArray([user.first_name, user.last_name]) },
          { label: 'Email', value: user.email },
        ]}
        handleEdit={() => setStep(0)}
      />
    </>
  );
};

export default StepReview;

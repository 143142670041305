import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { selectClinicianUserInitQuery, ClinicianUser } from './queries';
import { ViewClinicianUsersComponent } from '../../../../components/Global';

type UrlParams = {
  clinician_id: string;
};

const ViewClinicianUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { clinician_id } = useParams<UrlParams>();

  const [user, setUser] = useState<ClinicianUser>({
    first_name: '',
    last_name: '',
  });
  const { data: clinicianUserInitData } = useQuery(selectClinicianUserInitQuery(clinician_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (clinicianUserInitData) {
      setUser(clinicianUserInitData.users_clinician_by_pk);
    }
  }, [clinicianUserInitData]);

  const handleEdit = () => {
    navigate(`/loc/clinicians/${clinician_id}/edit`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Home', link: '/loc/home' },
        { label: 'Clinicians', link: `/loc/clinicians` },
        { label: StringUtils.stringFromArray([user.first_name, user.last_name]) },
      ]}>
      <ViewClinicianUsersComponent clinicianId={clinician_id!} handleEdit={handleEdit} />
    </DashboardLayout>
  );
};

export default ViewClinicianUsers;

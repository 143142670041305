import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useQuery } from '@apollo/client';
import { selectOrganisationUsersInitQuery, OrganisationUsersPageState } from './queries';
import { ListOrganisationUsersComponent } from '../../../../../components/Global';

type UrlParams = {
  organisation_id: string;
};

const ListOrganisationUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { organisation_id } = useParams<UrlParams>();

  const [pageState, setPageState] = useState<OrganisationUsersPageState>({
    name: '',
  });
  const { data: organisationsInitData } = useQuery(selectOrganisationUsersInitQuery(organisation_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (organisationsInitData) {
      setPageState(organisationsInitData.organisations_by_pk);
    }
  }, [organisationsInitData]);

  const handleView = (userId: string) => {
    navigate(`/admin/organisations/${organisation_id}/users/${userId}`);
  };

  const handleEdit = (userId: string) => {
    navigate(`/admin/organisations/${organisation_id}/users/${userId}/edit`);
  };

  const handleCreate = () => {
    navigate(`/admin/organisations/${organisation_id}/users/create`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Organisations', link: '/admin/organisations' },
        { label: pageState.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Users' },
      ]}>
      <ListOrganisationUsersComponent organisationId={organisation_id!} handleCreate={handleCreate} handleEdit={handleEdit} handleView={handleView} />
    </DashboardLayout>
  );
};

export default ListOrganisationUsers;

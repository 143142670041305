import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { createOrganisationUserInitQuery, Organisation } from './queries';
import { useQuery } from '@apollo/client';
import { CreateOrganisationUsersComponent } from '../../../../../components/Global';

type UrlParams = {
  organisation_id: string;
};

const CreateOrganisationUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
  });

  const { data: createOrganisationUserInitData } = useQuery(createOrganisationUserInitQuery(organisation_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (createOrganisationUserInitData) {
      setOrganisation(createOrganisationUserInitData.organisations_by_pk);
    }
  }, [createOrganisationUserInitData]);

  const handleFinish = (id: string) => {
    navigate(`/admin/organisations/${organisation_id}/users/${id}`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Organisations', link: '/admin/organisations' },
        { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Users', link: `/admin/organisations/${organisation_id}/users` },
        { label: 'Create' },
      ]}>
      <CreateOrganisationUsersComponent organisationId={organisation_id!} handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default CreateOrganisationUsers;

import React, { useReducer } from 'react';
import { CustomBackdrop } from '@blumtechgroup/blum-react-core-components';
import { organisationUserReducer } from '../Common';
import Steps from '../Common/components/Steps';
import { insertOrganisationUserMutation, InsertOrganisationUserResponse } from './mutations';
import { useMutation } from '@apollo/client';

interface Props {
  organisationId?: string;
  handleFinish: (userId: string) => void;
}

const CreateOrganisationUsersComponent = ({ organisationId, handleFinish }: Props): React.ReactElement => {
  const [user, dispatchUser] = useReducer(organisationUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
  });

  const [createOrganisationUser, { loading }] = useMutation<InsertOrganisationUserResponse>(insertOrganisationUserMutation);

  const handleSave = async () => {
    const createVariables = {
      object: {
        ...user,
        organisation_id: organisationId,
      },
    };
    const { data } = await createOrganisationUser({ variables: createVariables });
    if (data) {
      handleFinish(data.insert_users_organisation_admin_one.id);
    }
  };

  return (
    <>
      <Steps completeLabel="Create" user={user} isEdit={false} dispatch={dispatchUser} handleSave={handleSave} />
      {loading && <CustomBackdrop label="Creating User" />}
    </>
  );
};

export default CreateOrganisationUsersComponent;

import React, { useEffect, useReducer } from 'react';
import { CustomBackdrop } from '@blumtechgroup/blum-react-core-components';
import { locationUserReducer, LocationUserReducerAction } from '../Common';
import Steps from '../Common/components/Steps';
import { useMutation, useQuery } from '@apollo/client';
import { editLocationUserInitQuery } from './queries';
import { updateLocationUserMutation, UpdateLocationUserResponse } from './mutations';

interface Props {
  userId: string;
  handleFinish: () => void;
}

const EditLocationUsersComponent = ({ userId, handleFinish }: Props): React.ReactElement => {
  const [user, dispatchUser] = useReducer(locationUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
  });

  const { data: editLocationUserInitData } = useQuery(editLocationUserInitQuery(userId), { fetchPolicy: 'no-cache' });
  const [updateLocationUser, { loading }] = useMutation<UpdateLocationUserResponse>(updateLocationUserMutation);

  useEffect(() => {
    if (editLocationUserInitData) {
      const { users_location_admin_by_pk } = editLocationUserInitData;
      dispatchUser({ type: LocationUserReducerAction.INIT, value: users_location_admin_by_pk });
    }
  }, [editLocationUserInitData]);

  const handleSave = async () => {
    const { email, ...obj } = user;

    const updateVariables = {
      pk_columns: {
        id: userId,
      },
      set: obj,
    };

    await updateLocationUser({ variables: updateVariables });

    handleFinish();
  };

  return (
    <>
      <Steps completeLabel="Save" isEdit={true} user={user} dispatch={dispatchUser} handleSave={handleSave} />
      {loading && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default EditLocationUsersComponent;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, InputBase } from '@mui/material';
import { CustomBackdrop, CustomSortableTable, CustomViewToggle, CustomViewToggleViewType, DashboardLayout, GridCard } from '@blumtechgroup/blum-react-core-components';
import { useQuery } from '@apollo/client';
import { selectLicenseTypesInitQuery, LicenseType } from './queries';
import { Search as SearchIcon, Clear as ClearSearchIcon } from '@mui/icons-material/';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { SearchIconContainer, SearchPaper } from './styled';

const ListLicenseTypes = (): React.ReactElement => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const [viewMode, setViewMode] = useLocalStorage<CustomViewToggleViewType>('org_license_types_list_view_mode', CustomViewToggleViewType.TABLE);

  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const { data: licensesInitData, loading } = useQuery(selectLicenseTypesInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (licensesInitData) {
      setLicenseTypes(licensesInitData.organisation_license_type_assignments);
    }
  }, [licensesInitData]);

  const handleView = (licenseTypeId: string) => {
    navigate(`/org/license-types/${licenseTypeId}`);
  };

  const rows = licenseTypes.map((item) => {
    return {
      key: item.license_type.id,
      actions: [
        {
          label: 'View',
          onClick: () => handleView(item.license_type.id),
        },
      ],
      columns: [
        {
          key: 'name',
          label: item.license_type.name,
        },
        {
          key: 'count',
          label: item.count,
        },
      ],
    };
  });

  return (
    <>
      <DashboardLayout breadcrumbs={[{ label: 'Home', link: '/org/home' }, { label: 'License Types' }]}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="right" alignItems="center">
              {viewMode === CustomViewToggleViewType.GRID && (
                <Grid item>
                  <SearchPaper variant="outlined">
                    <InputBase
                      sx={{ width: '100%' }}
                      placeholder="Search"
                      value={searchValue}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value || '')}
                      startAdornment={
                        <SearchIconContainer>
                          <SearchIcon />
                        </SearchIconContainer>
                      }
                      endAdornment={
                        <SearchIconContainer>
                          <ClearSearchIcon onClick={() => setSearchValue('')} />
                        </SearchIconContainer>
                      }
                      inputProps={{ 'aria-label': 'search list' }}
                    />
                  </SearchPaper>
                </Grid>
              )}
              <Grid item>
                <CustomViewToggle value={viewMode} handleChangeView={setViewMode} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {viewMode === CustomViewToggleViewType.TABLE ? (
              <CustomSortableTable
                searchable={['name']}
                title={'License Types'}
                counter={'filtered'}
                headers={[
                  {
                    key: 'name',
                    label: 'Name',
                  },
                  {
                    key: 'count',
                    label: 'Count',
                  },
                ]}
                rows={rows}
              />
            ) : (
              <Grid container spacing={2}>
                {licenseTypes
                  .filter((item) => item.license_type.name.toLowerCase().includes(searchValue.toLowerCase()))
                  .map((item) => (
                    <GridCard
                      fill={true}
                      key={item.license_type.id}
                      gridSize={4}
                      title={item.license_type.name}
                      counter={item.count}
                      handleView={() => handleView(item.license_type.id)}
                    />
                  ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </DashboardLayout>
      {loading && <CustomBackdrop label="Loading License types" />}
    </>
  );
};

export default ListLicenseTypes;

import { Routes, Route, useNavigate } from 'react-router-dom';
import Splash from './views/Public/Splash';
import SignIn from './views/Public/SignIn';
import ResetPassword from './views/Public/ResetPassword';
import InitialSignIn from './views/Public/InitialSignIn';
import { NotAuthorised, NotFound, ProtectedRoleRoute } from '@blumtechgroup/blum-react-core-components';
import { AuthContext, adminRoles, orgRoles, locRoles, clinicianRoles } from './components/AuthProvider';
import { useContext } from 'react';

// Admin view
import {
  Home as AdminHome,

  // Users
  ListAdminUsers as AdminListUsers,
  ViewAdminUser as AdminViewUsers,
  CreateAdminUser as AdminCreateUsers,
  EditAdminUser as AdminEditUsers,

  // Requested Users
  ListRequestedUsers,
  ApproveRequestedUser,

  // Organisations
  ListOrganisations as AdminListOrganisations,
  ViewOrganisations as AdminViewOrganisations,
  CreateOrganisations as AdminCreateOrganisations,
  EditOrganisations as AdminEditOrganisations,

  // Organisation users
  ListOrganisationUsers as AdminListOrganisationUsers,
  ViewOrganisationUsers as AdminViewOrganisationUsers,
  CreateOrganisationUsers as AdminCreateOrganisationUsers,
  EditOrganisationsUsers as AdminEditOrganisationsUsers,

  // Locations
  ListLocations as AdminListLocations,
  ViewLocations as AdminViewLocations,
  CreateLocations as AdminCreateLocations,
  EditLocations as AdminEditLocations,

  // Location users
  ListLocationUsers as AdminListLocationUsers,
  ViewLocationUsers as AdminViewLocationUsers,
  CreateLocationUsers as AdminCreateLocationUsers,
  EditLocationUsers as AdminEditLocationUsers,

  // Clinicians
  ListClinicianUsers as AdminListClinicianUsers,
  ViewClinicianUsers as AdminViewClinicianUsers,
  CreateCliniciansUsers as AdminCreateClinicianUsers,
  EditCliniciansUsers as AdminEditCliniciansUsers,

  // License types
  ListLicenseTypes as AdminListLicenseTypes,
  ViewLicenseTypes as AdminViewLicenseTypes,
  CreateLicenseTypes as AdminCreateLicenseTypes,
  EditLicenseTypes as AdminEditLicenseTypes,

  // Licenses
  ViewOrganisationLicenses as AdminViewOrganisationLicenses,
  ViewLocationLicenses as AdminViewLocationLicenses,

  // TBSA
  ListTbsaResults as AdminListTbsaResults,
} from './views/Admin';

// Organisation view
import {
  Home as OrgHome,

  // Organisation users
  ListOrganisationUsers as OrgListOrganisationUsers,
  ViewOrganisationUsers as OrgViewOrganisationUsers,
  CreateOrganisationUsers as OrgCreateOrganisationUsers,
  EditOrganisationUsers as OrgEditOrganisationUsers,

  // Locations
  ListLocations as OrgListLocations,
  ViewLocation as OrgViewLocation,
  CreateLocation as OrgCreateLocation,
  EditLocation as OrgEditLocation,

  // Location users
  ListLocationUsers as OrgListLocationUsers,
  ViewLocationUsers as OrgViewLocationUsers,
  CreateLocationUsers as OrgCreateLocationUsers,
  EditLocationUsers as OrgEditLocationUsers,

  // Clinicians
  ListClinicianUsers as OrgListClinicianUsers,
  ViewClinicianUsers as OrgViewClinicianUsers,
  CreateClinicianUsers as OrgCreateClinicianUsers,
  EditClinicianUsers as OrgEditClinicianUsers,

  // License types
  ListLicenseTypes as OrgListLicenseTypes,

  // Licenses
  ViewOrganisationLicenses as OrgViewOrganisationLicenses,
  ViewLocationLicenses as OrgViewLocationLicenses,

  // TBSA
  ListTbsaResults as OrgListTbsaResults,
} from './views/Organisation';

// Location view
import {
  Home as LocHome,

  // Location users
  ListLocationUsers as LocListLocationUsers,
  ViewLocationUsers as LocViewLocationUsers,
  CreateLocationUsers as LocCreateLocationUsers,
  EditLocationUsers as LocEditLocationUsers,

  // Clinicians
  ListClinicianUsers as LocListClinicianUsers,
  ViewClinicianUsers as LocViewClinicianUsers,
  CreateClinicianUsers as LocCreateClinicianUsers,
  EditClinicianUsers as LocEditClinicianUsers,

  // Tbsa results
  ListTbsaResults as LocListTbsaResults,
} from './views/Location';

// Clinician view
import { Home as ClinicianHome } from './views/Clinician';

const AppRoutes = () => {
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }

  const { userRole } = authContext;

  return (
    <Routes>
      <Route path="/" element={<Splash />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/initial-sign-in" element={<InitialSignIn />} />

      {/* admin view */}
      <Route element={<ProtectedRoleRoute fallbackComponent={<NotAuthorised goBack={navigate} />} allowedRoles={adminRoles} userRole={userRole} />}>
        <Route path="/admin/home" element={<AdminHome />} />

        {/* users */}
        <Route path="/admin/users" element={<AdminListUsers />} />
        <Route path="/admin/users/:admin_user_id" element={<AdminViewUsers />} />
        <Route path="/admin/users/create" element={<AdminCreateUsers />} />
        <Route path="/admin/users/:admin_user_id/edit" element={<AdminEditUsers />} />

        {/* requested users */}
        <Route path="/admin/requested-users" element={<ListRequestedUsers />} />
        <Route path="/admin/requested-users/:email/approve" element={<ApproveRequestedUser />} />

        {/* organisations */}
        <Route path="/admin/organisations" element={<AdminListOrganisations />} />
        <Route path="/admin/organisations/:organisation_id" element={<AdminViewOrganisations />} />
        <Route path="/admin/organisations/create" element={<AdminCreateOrganisations />} />
        <Route path="/admin/organisations/:organisation_id/edit" element={<AdminEditOrganisations />} />

        {/* organisation users */}
        <Route path="/admin/organisations/:organisation_id/users" element={<AdminListOrganisationUsers />} />
        <Route path="/admin/organisations/:organisation_id/users/:user_id" element={<AdminViewOrganisationUsers />} />
        <Route path="/admin/organisations/:organisation_id/users/create" element={<AdminCreateOrganisationUsers />} />
        <Route path="/admin/organisations/:organisation_id/users/:user_id/edit" element={<AdminEditOrganisationsUsers />} />

        {/* locations */}
        <Route path="/admin/organisations/:organisation_id/locations" element={<AdminListLocations />} />
        <Route path="/admin/organisations/:organisation_id/locations/:location_id" element={<AdminViewLocations />} />
        <Route path="/admin/organisations/:organisation_id/locations/create" element={<AdminCreateLocations />} />
        <Route path="/admin/organisations/:organisation_id/locations/:location_id/edit" element={<AdminEditLocations />} />

        {/* location users */}
        <Route path="/admin/organisations/:organisation_id/locations/:location_id/users" element={<AdminListLocationUsers />} />
        <Route path="/admin/organisations/:organisation_id/locations/:location_id/users/:user_id" element={<AdminViewLocationUsers />} />
        <Route path="/admin/organisations/:organisation_id/locations/:location_id/users/create" element={<AdminCreateLocationUsers />} />
        <Route path="/admin/organisations/:organisation_id/locations/:location_id/users/:user_id/edit" element={<AdminEditLocationUsers />} />

        {/* clinicians */}
        <Route path="/admin/organisations/:organisation_id/locations/:location_id/clinicians" element={<AdminListClinicianUsers />} />
        <Route path="/admin/organisations/:organisation_id/locations/:location_id/clinicians/:clinician_id" element={<AdminViewClinicianUsers />} />
        <Route path="/admin/organisations/:organisation_id/locations/:location_id/clinicians/create" element={<AdminCreateClinicianUsers />} />
        <Route path="/admin/organisations/:organisation_id/locations/:location_id/clinicians/:clinician_id/edit" element={<AdminEditCliniciansUsers />} />

        {/* license types */}
        <Route path="/admin/license-types" element={<AdminListLicenseTypes />} />
        <Route path="/admin/license-types/:license_type_id" element={<AdminViewLicenseTypes />} />
        <Route path="/admin/license-types/create" element={<AdminCreateLicenseTypes />} />
        <Route path="/admin/license-types/:license_type_id/edit" element={<AdminEditLicenseTypes />} />

        {/* licenses */}
        <Route path="/admin/license-types/:license_type_id/organisations/:organisation_id" element={<AdminViewOrganisationLicenses />} />
        <Route path="/admin/license-types/:license_type_id/organisations/:organisation_id/locations/:location_id" element={<AdminViewLocationLicenses />} />

        {/* TBSA results */}
        <Route path="/admin/organisations/:organisation_id/locations/:location_id/tbsa" element={<AdminListTbsaResults />} />
      </Route>

      {/* organisation view */}
      <Route element={<ProtectedRoleRoute fallbackComponent={<NotAuthorised goBack={navigate} />} allowedRoles={orgRoles} userRole={userRole} />}>
        <Route path="/org/home" element={<OrgHome />} />

        {/* organisation users */}
        <Route path="/org/users" element={<OrgListOrganisationUsers />} />
        <Route path="/org/users/:user_id" element={<OrgViewOrganisationUsers />} />
        <Route path="/org/users/create" element={<OrgCreateOrganisationUsers />} />
        <Route path="/org/users/:user_id/edit" element={<OrgEditOrganisationUsers />} />

        {/* locations */}
        <Route path="/org/locations" element={<OrgListLocations />} />
        <Route path="/org/locations/:location_id" element={<OrgViewLocation />} />
        <Route path="/org/locations/create" element={<OrgCreateLocation />} />
        <Route path="/org/locations/:location_id/edit" element={<OrgEditLocation />} />

        {/* location users */}
        <Route path="/org/locations/:location_id/users" element={<OrgListLocationUsers />} />
        <Route path="/org/locations/:location_id/users/:user_id" element={<OrgViewLocationUsers />} />
        <Route path="/org/locations/:location_id/users/create" element={<OrgCreateLocationUsers />} />
        <Route path="/org/locations/:location_id/users/:user_id/edit" element={<OrgEditLocationUsers />} />

        {/* clinicians */}
        <Route path="/org/locations/:location_id/clinicians" element={<OrgListClinicianUsers />} />
        <Route path="/org/locations/:location_id/clinicians/:clinician_id" element={<OrgViewClinicianUsers />} />
        <Route path="/org/locations/:location_id/clinicians/create" element={<OrgCreateClinicianUsers />} />
        <Route path="/org/locations/:location_id/clinicians/:clinician_id/edit" element={<OrgEditClinicianUsers />} />

        {/* license types */}
        <Route path="/org/license-types" element={<OrgListLicenseTypes />} />

        {/* licenses */}
        <Route path="/org/license-types/:license_type_id" element={<OrgViewOrganisationLicenses />} />
        <Route path="/org/license-types/:license_type_id/locations/:location_id" element={<OrgViewLocationLicenses />} />

        {/* tbsa results */}
        <Route path="/org/locations/:location_id/tbsa" element={<OrgListTbsaResults />} />
      </Route>

      {/* location view */}
      <Route element={<ProtectedRoleRoute fallbackComponent={<NotAuthorised goBack={navigate} />} allowedRoles={locRoles} userRole={userRole} />}>
        <Route path="/loc/home" element={<LocHome />} />

        {/* location users */}
        <Route path="/loc/users" element={<LocListLocationUsers />} />
        <Route path="/loc/users/:user_id" element={<LocViewLocationUsers />} />
        <Route path="/loc/users/create" element={<LocCreateLocationUsers />} />
        <Route path="/loc/users/:user_id/edit" element={<LocEditLocationUsers />} />

        {/* clinicians */}
        <Route path="/loc/clinicians" element={<LocListClinicianUsers />} />
        <Route path="/loc/clinicians/:clinician_id" element={<LocViewClinicianUsers />} />
        <Route path="/loc/clinicians/create" element={<LocCreateClinicianUsers />} />
        <Route path="/loc/clinicians/:clinician_id/edit" element={<LocEditClinicianUsers />} />

        {/* tbsa results */}
        <Route path="/loc/tbsa" element={<LocListTbsaResults />} />
      </Route>

      {/* clinician view */}
      <Route element={<ProtectedRoleRoute fallbackComponent={<NotAuthorised goBack={navigate} />} allowedRoles={clinicianRoles} userRole={userRole} />}>
        <Route path="/clinician/home" element={<ClinicianHome />} />
      </Route>

      <Route path="*" element={<NotFound goBack={navigate} />} />
    </Routes>
  );
};

export default AppRoutes;

import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectLicenseTypesInitQuery = () => ({
  license_types: [
    {
      id: types.string,
      name: types.string,
    },
  ],
});

type X = Types.Unpacked<typeof _selectLicenseTypesInitQuery>;
export type LicenseType = X['license_types'][0];

export const selectLicenseTypesInitQuery = () =>
  gql`
    ${query(_selectLicenseTypesInitQuery())}
  `;

export const getNumberOfActiveLicensesQuery = gql`
  query ($licenseTypeId: uuid!) {
    organisation_license_type_assignments_aggregate(where: { license_type_id: { _eq: $licenseTypeId } }) {
      aggregate {
        count
      }
    }
  }
`;

export interface GetNumberOfActiveLicensesVariables {
  licenseTypeId: string;
}

export interface GetNumberOfActiveLicensesResponse {
  organisation_license_type_assignments_aggregate: {
    aggregate: {
      count: number;
    };
  };
}

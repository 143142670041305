import React from 'react';
import { ReadOnlyBlock } from '@blumtechgroup/blum-react-core-components';
import { LicenceType } from '../..';

interface Props {
  licenseType: LicenceType;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ licenseType, setStep }: Props): React.ReactElement => <ReadOnlyBlock items={[{ label: 'Name', value: licenseType.name }]} handleEdit={() => setStep(0)} />;

export default StepReview;

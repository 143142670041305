import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _editLocationInitQuery = (locationId: string) => ({
  locations_by_pk: params(
    { id: rawString(locationId) },
    {
      id: types.string,
      name: types.string,
      active: types.boolean,
      license_type_assignments: [
        {
          license_type_id: types.string,
          count: types.number,
        },
      ],
      organisation: {
        name: types.string,
        license_type_assignments: [
          {
            license_type_id: types.string,
            count: types.number,
            license_type: {
              name: types.string,
            },
          },
        ],
        location_license_type_assignments: [
          {
            license_type_id: types.string,
            location_id: types.string,
            count: types.number,
          },
        ],
      },
    }
  ),
});

type X = Types.Unpacked<typeof _editLocationInitQuery>;
export type Organisation = X['locations_by_pk']['organisation'];

export const editLocationInitQuery = (locationId: string) =>
  gql`
    ${query(_editLocationInitQuery(locationId))}
  `;

export const getAllocatedLicensesQuery = gql`
  query ($licenseTypeIds: [uuid]!) {
    location_license_type_assignments(where: { license_type_id: { _in: $licenseTypeIds } }) {
      license_type_id
      location_id
      count
    }
  }
`;

export const getAllocatedLicensesForOrganisationQuery = gql`
  query ($licenseTypeIds: [uuid]!, $organisationId: uuid!) {
    location_license_type_assignments(where: { _and: [{ license_type_id: { _in: $licenseTypeIds } }, { organisation_id: { _eq: $organisationId } }] }) {
      license_type_id
      location_id
      count
    }
  }
`;

export interface GetAllocatedLicensesVariables {
  licenseTypeIds: string[];
  organisationId?: string;
}

export interface GetAllocatedLicensesResponse {
  location_license_type_assignments: { license_type_id: string; location_id: string; count: number }[];
}

import { gql } from '@apollo/client';

export const insertOrganisationUserMutation = gql`
  mutation ($object: users_organisation_admin_insert_input!) {
    insert_users_organisation_admin_one(object: $object) {
      id
    }
  }
`;

export interface InsertOrganisationUserResponse {
  insert_users_organisation_admin_one: {
    id: string;
  };
}

import React from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useNavigate } from 'react-router-dom';
import { LocationCreateComponent } from '../../../../components/Global';

const LocationCreate = (): React.ReactElement => {
  const navigate = useNavigate();

  const handleFinish = (locationId: string) => {
    navigate(`/org/locations/${locationId}`);
  };

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Home', link: '/org/home' }, { label: 'Hospitals', link: `/org/locations` }, { label: 'Create' }]}>
      <LocationCreateComponent handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default LocationCreate;

import { gql } from '@apollo/client';

export const updateLocationMutation = gql`
  mutation (
    $pk_columns: locations_pk_columns_input!
    $set: locations_set_input!
    $license_types: [location_license_type_assignments_insert_input!]!
    $delete_license_types: [uuid]!
    $location_id: uuid!
  ) {
    update_locations_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
    insert_location_license_type_assignments(objects: $license_types, on_conflict: { constraint: location_license_type_assignments_pkey, update_columns: [count] }) {
      returning {
        location_id
        license_type_id
      }
    }
    delete_location_license_type_assignments(where: { _and: [{ license_type_id: { _in: $delete_license_types } }, { location_id: { _eq: $location_id } }] }) {
      affected_rows
    }
  }
`;

import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { selectLocationUsersInitQuery, Location } from './queries';
import { ListLocationUsersComponent } from '../../../../../components/Global';

type UrlParams = {
  location_id: string;
};

const ListLocationUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
  });
  const { data: locationInitData } = useQuery(selectLocationUsersInitQuery(location_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationInitData) {
      setLocation(locationInitData.locations_by_pk);
    }
  }, [locationInitData]);

  const handleView = (userId: string) => {
    navigate(`/org/locations/${location_id}/users/${userId}`);
  };

  const handleEdit = (userId: string) => {
    navigate(`/org/locations/${location_id}/users/${userId}/edit`);
  };

  const handleCreate = () => {
    navigate(`/org/locations/${location_id}/users/create`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Home', link: '/org/home' },
        { label: 'Hospitals', link: `/org/locations` },
        { label: location.name, link: `/org/locations/${location_id}` },
        { label: 'Users' },
      ]}>
      <ListLocationUsersComponent locationId={location_id!} handleView={handleView} handleEdit={handleEdit} handleCreate={handleCreate} />
    </DashboardLayout>
  );
};

export default ListLocationUsers;

import React, { useState, useEffect } from 'react';
import {
  CustomSortableTable,
  CustomViewToggle,
  CustomViewToggleViewType,
  GridActionCard,
  GridCard,
  SortableTableFilter,
  SortableTableRow,
} from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { FormControl, Grid, InputBase, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useQuery } from '@apollo/client';
import { selectCliniciansInitQuery, Clinician, LicenseType } from './queries';
import useLocalStorage from '../../../../../../hooks/useLocalStorage';
import { Search as SearchIcon, Clear as ClearSearchIcon } from '@mui/icons-material/';
import { SearchIconContainer, SearchPaper } from './styled';

interface PageState {
  clinicians: Clinician[];
  licenseTypes: LicenseType[];
}

const NoLicenseLabel = 'No license assigned';

interface Props {
  locationId?: string;
  handleView: (clinicianId: string) => void;
  handleEdit: (clinicianId: string) => void;
  handleCreate: () => void;
}

const ListClinicianUsersComponent = ({ locationId, handleView, handleEdit, handleCreate }: Props): React.ReactElement => {
  const [searchValue, setSearchValue] = useState<string>('');

  const [activeFiltered, setActiveFiltered] = useState<boolean>(true);
  const [licenseTypeFiltered, setLicenseTypeFiltered] = useState<string>('');

  const [viewMode, setViewMode] = useLocalStorage<CustomViewToggleViewType>('admin_clinicians_list_view_mode', CustomViewToggleViewType.TABLE);

  const [pageState, setPageState] = useState<PageState>({
    clinicians: [],
    licenseTypes: [],
  });
  const { data: locationInitData } = useQuery(selectCliniciansInitQuery(locationId), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationInitData) {
      setPageState({
        clinicians: locationInitData.users_clinician,
        licenseTypes: locationInitData.location_license_type_assignments,
      });
    }
  }, [locationInitData]);

  const rows = pageState.clinicians.map((item) => {
    return {
      key: item.id,
      actions: [
        {
          label: 'View',
          onClick: () => handleView(item.id),
        },
        {
          label: 'Edit',
          onClick: () => handleEdit(item.id),
        },
      ],
      columns: [
        {
          key: 'full_name',
          label: StringUtils.stringFromArray([item.first_name, item.last_name]),
        },
        {
          key: 'email',
          label: item.email,
        },
        {
          key: 'license',
          label: item.license ? item.license.license_type.name : NoLicenseLabel,
        },
        {
          key: 'active',
          label: StringUtils.booleanToYesNo(item.active),
        },
      ],
    };
  });

  const activeFilter: SortableTableFilter = {
    key: 'activeFilter',
    label: 'Active',
    filter: (rows: SortableTableRow[]) =>
      rows.filter((r) => {
        const col = r.columns.find((c) => c.key === 'active');
        if (col) {
          return activeFiltered ? col.label === 'Yes' : col.label === 'No';
        }
        return true;
      }),
    component: (
      <ToggleButtonGroup sx={{ height: '59px' }} size="medium" value={[activeFiltered]} color="primary" onChange={() => setActiveFiltered(!activeFiltered)}>
        <ToggleButton key="active" value={true}>
          Active
        </ToggleButton>

        <ToggleButton key="inactive" value={false}>
          Inactive
        </ToggleButton>
      </ToggleButtonGroup>
    ),
  };

  const licenseTypeFilter: SortableTableFilter = {
    key: 'licenseTypeFilter',
    label: 'License Type',
    filter: (rows: SortableTableRow[]) =>
      rows.filter((r) => {
        const col = r.columns.find((c) => c.key === 'license');
        if (col && licenseTypeFiltered) {
          return licenseTypeFiltered === col.label;
        }

        return true;
      }),
    component: (
      <FormControl sx={{ minWidth: '250px' }}>
        <InputLabel id="select-license-label">License Type</InputLabel>
        <Select labelId="select-license-label" id="select-license" value={licenseTypeFiltered} label="License Type" onChange={(e) => setLicenseTypeFiltered(e.target.value)}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={NoLicenseLabel}>{NoLicenseLabel}</MenuItem>
          {pageState.licenseTypes.map((item) => (
            <MenuItem value={item.license_type.name}>{item.license_type.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
  };

  const filters = [activeFilter, licenseTypeFilter];

  const filterRows = (): SortableTableRow[] => {
    let filteredRows: SortableTableRow[] = rows;
    filters.forEach((f) => {
      filteredRows = f.filter(filteredRows);
    });
    return filteredRows;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="right" alignItems="center">
          {viewMode === CustomViewToggleViewType.GRID && (
            <>
              <Grid item>
                <SearchPaper variant="outlined">
                  <InputBase
                    sx={{ width: '100%' }}
                    placeholder="Search"
                    value={searchValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value || '')}
                    startAdornment={
                      <SearchIconContainer>
                        <SearchIcon />
                      </SearchIconContainer>
                    }
                    endAdornment={
                      <SearchIconContainer>
                        <ClearSearchIcon onClick={() => setSearchValue('')} />
                      </SearchIconContainer>
                    }
                    inputProps={{ 'aria-label': 'search list' }}
                  />
                </SearchPaper>
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  color="primary"
                  value={activeFiltered ? 'active' : 'inactive'}
                  exclusive
                  onChange={(_event: React.MouseEvent<HTMLElement>, newAlignment: string) => setActiveFiltered(newAlignment === 'active')}
                  aria-label="Platform">
                  <ToggleButton value="active">Active</ToggleButton>
                  <ToggleButton value="inactive">Inactive</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </>
          )}
          <Grid item>
            <CustomViewToggle value={viewMode} handleChangeView={setViewMode} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {viewMode === CustomViewToggleViewType.TABLE ? (
          <CustomSortableTable
            filters={filters}
            searchable={['name']}
            title={'Clinicians'}
            counter={'filtered'}
            actions={[
              {
                key: 'create',
                label: 'Create',
                onClick: handleCreate,
              },
            ]}
            headers={[
              {
                key: 'full_name',
                label: 'Full name',
              },
              {
                key: 'email',
                label: 'Email',
              },
              {
                key: 'license',
                label: 'License',
              },
              {
                key: 'active',
                label: 'Active',
              },
            ]}
            rows={filterRows()}
          />
        ) : (
          <Grid container spacing={2}>
            <GridActionCard gridSize={4} fill={true} onClick={handleCreate} />
            {pageState.clinicians
              .filter((item) => item.active === activeFiltered)
              .filter((item) => StringUtils.stringFromArray([item.first_name.toLowerCase(), item.last_name.toLowerCase()]).includes(searchValue.toLowerCase()))
              .map((item) => (
                <GridCard
                  fill={true}
                  key={item.id}
                  gridSize={4}
                  title={StringUtils.stringFromArray([item.first_name, item.last_name])}
                  subTitle={item.email}
                  handleEdit={() => handleEdit(item.id)}
                  handleView={() => handleView(item.id)}
                />
              ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ListClinicianUsersComponent;

import React, { useCallback } from 'react';
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import { AdminUser, ValidationErrors, validationErrors, AdminUserReducerAction } from '../..';
import { Root, TextField } from './styled';
import { Types, ValidatorsUtils } from '@blumtechgroup/blum-ui-utils';

interface Props {
  user: AdminUser;
  errors: ValidationErrors;
  isEdit: boolean;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: AdminUserReducerAction; value: any }>;
}

const StepDetails = ({ user, errors, isEdit, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: Types.UnknownObject = {};
      if (field === 'first_name') {
        errorItems.first_name = !user.first_name ? validationErrors.first_name : false;
      }
      if (field === 'last_name') {
        errorItems.last_name = !user.last_name ? validationErrors.last_name : false;
      }
      if (field === 'email') {
        errorItems.email_invalid = !ValidatorsUtils.validateEmailAddress(user.email) ? validationErrors.email_invalid : false;
        errorItems.email = !user.email ? validationErrors.email : false;
      }
      setErrors({ ...errors, ...errorItems });
    },
    [user, errors, setErrors]
  );

  return (
    <Root>
      <TextField
        data-qa="first-name-textfield"
        required
        label="First name"
        variant="outlined"
        error={errors.first_name as boolean}
        helperText={errors.first_name}
        value={user.first_name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: AdminUserReducerAction.FIRST_NAME, value: event.target.value })}
        onKeyUp={() => validate('first_name')}
        onBlur={() => validate('first_name')}
      />
      <TextField
        data-qa="last-name-textfield"
        required
        label="Last name"
        variant="outlined"
        error={errors.last_name as boolean}
        helperText={errors.last_name}
        value={user.last_name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: AdminUserReducerAction.LAST_NAME, value: event.target.value })}
        onKeyUp={() => validate('last_name')}
        onBlur={() => validate('last_name')}
      />
      <TextField
        data-qa="email-textfield"
        required
        label="Email"
        disabled={isEdit}
        variant="outlined"
        value={user.email}
        error={(errors.email as boolean) || (errors.email_invalid as boolean)}
        helperText={errors.email || errors.email_invalid}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: AdminUserReducerAction.EMAIL, value: event.target.value })}
        onKeyUp={() => validate('email')}
        onBlur={() => validate('email')}
      />
      <FormControl>
        <FormControlLabel
          label="Active"
          control={
            <Switch
              checked={user.active}
              color="primary"
              data-qa="active-toggle"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch({
                  type: AdminUserReducerAction.ACTIVE,
                  value: event.target.checked,
                })
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
        />
      </FormControl>
    </Root>
  );
};

export default StepDetails;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { useQuery } from '@apollo/client';
import { editOrganisationUserInitQuery, User } from './queries';
import { EditOrganisationUsersComponent } from '../../../../components/Global';

type UrlParams = {
  user_id: string;
};

const EditOrganisationUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<User>({
    first_name: '',
    last_name: '',
    organisation: {
      name: '',
    },
  });

  const { data: editOrganisationUserInitData } = useQuery(editOrganisationUserInitQuery(user_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (editOrganisationUserInitData) {
      const { users_organisation_admin_by_pk } = editOrganisationUserInitData;
      setUser(users_organisation_admin_by_pk);
    }
  }, [editOrganisationUserInitData]);

  const handleFinish = () => {
    navigate(`/org/users/${user_id}`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Home', link: '/org/home' },
        { label: 'Users', link: `/org/users` },
        { label: StringUtils.stringFromArray([user.first_name, user.last_name]), link: `/org/users/${user_id}` },
        { label: 'Edit' },
      ]}>
      <EditOrganisationUsersComponent userId={user_id!} handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default EditOrganisationUsers;

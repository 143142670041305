import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _editOrganisationUserInitQuery = (userId: string) => ({
  users_organisation_admin_by_pk: params(
    { id: rawString(userId) },
    {
      first_name: types.string,
      last_name: types.string,
      email: types.string,
      active: types.boolean,
    }
  ),
});

export const editOrganisationUserInitQuery = (userId: string) =>
  gql`
    ${query(_editOrganisationUserInitQuery(userId))}
  `;

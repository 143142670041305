import { gql } from '@apollo/client';
import { AdminUser, OrganisationUser, LocationUser, ClinicianUser } from '../../types';

export const getUserRoleQuery = gql`
  query ($id: uuid!) {
    user_roles(where: { sub_id: { _eq: $id } }) {
      role
    }
  }
`;

export interface GetUserVariables {
  id: string;
}

export interface GetUserRoleResponse {
  user_roles: { role: string }[];
}

export const getAdminUserQuery = gql`
  query ($id: uuid!) {
    users_admin(where: { sub_id: { _eq: $id } }) {
      id
      email
      first_name
      last_name
      full_name
      sub_id
      active
    }
  }
`;

export interface GetAdminUserResponse {
  users_admin: AdminUser[];
}

export const getOrganisationUserQuery = gql`
  query ($id: uuid!) {
    users_organisation_admin(where: { sub_id: { _eq: $id } }) {
      id
      email
      first_name
      last_name
      sub_id
      active
      organisation {
        name
        active
      }
    }
  }
`;

export interface GetOrganisationUserResponse {
  users_organisation_admin: OrganisationUser[];
}

export const getLocationUserQuery = gql`
  query ($id: uuid!) {
    users_location_admin(where: { sub_id: { _eq: $id } }) {
      id
      email
      first_name
      last_name
      sub_id
      active
      organisation {
        name
        active
      }
      location {
        name
        active
      }
    }
  }
`;

export interface GetLocationUserResponse {
  users_location_admin: LocationUser[];
}

export const getClinicianUserQuery = gql`
  query ($id: uuid) {
    users_clinician(where: { sub_id: { _eq: $id } }) {
      id
      email
      first_name
      last_name
      sub_id
      active
      organisation {
        name
        active
      }
      location {
        name
        active
      }
    }
  }
`;

export interface GetClinicianUserResponse {
  users_clinician: ClinicianUser[];
}

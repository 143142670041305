import { query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

export const _homeInitQuery = () => ({
  users_organisation_admin_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  locations_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  organisation_license_type_assignments_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
});

export type HomePageState = Types.Unpacked<typeof _homeInitQuery>;

export const homeInitQuery = () =>
  gql`
    ${query(_homeInitQuery())}
  `;

import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { selectClinicianUserInitQuery, ClinicianUser } from './queries';
import { ViewClinicianUsersComponent } from '../../../../../components/Global';

type UrlParams = {
  location_id: string;
  clinician_id: string;
};

const ViewClinicianUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { location_id, clinician_id } = useParams<UrlParams>();

  const [user, setUser] = useState<ClinicianUser>({
    first_name: '',
    last_name: '',
    organisation: {
      name: '',
    },
    location: {
      name: '',
    },
  });
  const { data: clinicianUserInitData } = useQuery(selectClinicianUserInitQuery(clinician_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (clinicianUserInitData) {
      setUser(clinicianUserInitData.users_clinician_by_pk);
    }
  }, [clinicianUserInitData]);

  const handleEdit = () => {
    navigate(`/org/locations/${location_id}/clinicians/${clinician_id}/edit`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Home', link: '/org/home' },
        { label: 'Hospitals', link: `/org/locations` },
        { label: user.location.name, link: `/org/locations/${location_id}` },
        { label: 'Clinicians', link: `/org/locations/${location_id}/clinicians` },
        { label: StringUtils.stringFromArray([user.first_name, user.last_name]) },
      ]}>
      <ViewClinicianUsersComponent clinicianId={clinician_id!} handleEdit={handleEdit} />
    </DashboardLayout>
  );
};

export default ViewClinicianUsers;

import { types, query, params, rawString } from 'typed-graphqlify';
import { Types } from '@blumtechgroup/blum-ui-utils';
import { gql } from '@apollo/client';

const _createLocationInitQuery = (organisationId: string) => ({
  organisations_by_pk: params(
    { id: rawString(organisationId) },
    {
      name: types.string,
    }
  ),
});

type X = Types.Unpacked<typeof _createLocationInitQuery>;
export type Organisation = X['organisations_by_pk'];

export const createLocationInitQuery = (organisationId: string) =>
  gql`
    ${query(_createLocationInitQuery(organisationId))}
  `;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { DashboardLayout, GridCard } from '@blumtechgroup/blum-react-core-components';
import { homeInitQuery, HomePageState } from './queries';

const Home = (): React.ReactElement => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState<HomePageState>({
    users_admin_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    organisations_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    license_types_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    requested_users_aggregate: {
      aggregate: {
        count: 0,
      },
    },
  });

  const { data: homeInitData } = useQuery(homeInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (homeInitData) {
      setPageState(homeInitData);
    }
  }, [homeInitData]);

  const handleAction = (link: string) => {
    navigate(link);
  };

  const actions = [
    {
      title: 'Users',
      counter: pageState.users_admin_aggregate.aggregate.count,
      createLink: '/admin/users/create',
      viewLink: '/admin/users',
    },
    {
      title: 'Organisations',
      counter: pageState.organisations_aggregate.aggregate.count,
      createLink: '/admin/organisations/create',
      viewLink: '/admin/organisations',
    },
    {
      title: 'License Types',
      counter: pageState.license_types_aggregate.aggregate.count,
      createLink: '/admin/license-types/create',
      viewLink: '/admin/license-types',
    },
    {
      title: 'Requested users',
      counter: pageState.requested_users_aggregate.aggregate.count,
      viewLink: '/admin/requested-users',
    },
  ];

  return (
    <DashboardLayout title="Admin Dashboard">
      <Grid container spacing={4}>
        {actions.map((action) => (
          <GridCard
            key={action.title}
            title={action.title}
            counter={action.counter}
            editLabel="Create"
            handleEdit={action.createLink ? () => handleAction(action.createLink) : undefined}
            handleView={() => handleAction(action.viewLink)}
          />
        ))}
      </Grid>
    </DashboardLayout>
  );
};

export default Home;

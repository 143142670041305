import React, { useState, useReducer, useEffect } from 'react';
import { CustomBackdrop, CustomDialog } from '@blumtechgroup/blum-react-core-components';
import { ApprovedUser, clinicianUserReducer } from '../Common';
import Steps from '../Common/components/Steps';
import { insertClinicianUserMutation, InsertClinicianUserResponse } from './mutations';
import {
  createClinicianUserInitQuery,
  Location,
  createClinicianUserQuery,
  createClinicianUserForLocationQuery,
  CreateClinicianUserVariables,
  CreateClinicianUserResponse,
} from './queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

interface Props {
  organisationId?: string;
  locationId?: string;
  approvedUser?: ApprovedUser;
  handleFinish: (userId: string) => void;
}

const CreateClinicianUsersComponent = ({ organisationId, locationId, approvedUser, handleFinish }: Props): React.ReactElement => {
  const [user, dispatchUser] = useReducer(clinicianUserReducer, {
    first_name: approvedUser?.first_name ?? '',
    last_name: approvedUser?.last_name ?? '',
    email: approvedUser?.email ?? '',
    active: true,
    license: undefined,
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [quotaExceeded, setQuotaExceeded] = useState<boolean>(false);

  const [location, setLocation] = useState<Location>({
    license_type_assignments: [],
    clinician_license_type_assignments: [],
  });

  const { data: createClinicianUserInitData } = useQuery(createClinicianUserInitQuery(locationId), { fetchPolicy: 'no-cache' });
  const [createClinicianUser] = useMutation<InsertClinicianUserResponse>(insertClinicianUserMutation);

  const [getLicenseAssignments] = useLazyQuery<CreateClinicianUserResponse, CreateClinicianUserVariables>(
    locationId ? createClinicianUserForLocationQuery : createClinicianUserQuery
  );

  useEffect(() => {
    if (createClinicianUserInitData && createClinicianUserInitData.locations?.length) {
      setLocation(createClinicianUserInitData.locations[0]);
    }
  }, [createClinicianUserInitData]);

  const handleSave = async () => {
    setSaving(true);

    const { license, ...obj } = user;

    if (!!license) {
      const { data: response } = await getLicenseAssignments({
        variables: {
          locationId: locationId,
          licenseTypeId: license.license_type_id,
        },
      });

      const activeUsers = response?.clinician_license_type_assignments_aggregate.aggregate.count ?? 0;
      const usersQuota = response?.location_license_type_assignments?.length ? response.location_license_type_assignments[0].count : 0;

      const licenseAllowed = activeUsers < usersQuota;
      setQuotaExceeded(!licenseAllowed);

      if (!licenseAllowed) {
        setSaving(false);

        return;
      }
    }

    const createVariables = {
      object: {
        ...obj,
        organisation_id: organisationId,
        location_id: locationId,
        ...(license
          ? {
              license: {
                data: {
                  license_type_id: license?.license_type_id,
                  organisation_id: organisationId,
                  location_id: locationId,
                },
              },
            }
          : {}),
      },
    };

    const { data } = await createClinicianUser({ variables: createVariables });
    if (data) {
      setSaving(false);
      handleFinish(data.insert_users_clinician_one.id);
    }
  };

  const availableLicenseTypes = location.license_type_assignments.filter((item) => {
    const assigned = location.clinician_license_type_assignments.filter((i) => i.license_type_id === item.license_type.id).length;

    return assigned < item.count;
  });

  return (
    <>
      <Steps completeLabel="Create" licenseTypes={availableLicenseTypes} isEdit={false} user={user} dispatch={dispatchUser} handleSave={handleSave} />
      {saving && <CustomBackdrop label="Creating User" />}
      <CustomDialog
        open={quotaExceeded}
        title={'Quota exceeded'}
        subtitle={'You reach a maximum number of active users per selected license type.'}
        message={'Please get in touch with the administrator to help you increase the number of active users per selected license.'}
        actions={[
          {
            label: 'Close',
            onClick: () => setQuotaExceeded(false),
          },
        ]}
        handleClose={() => setQuotaExceeded(false)}
      />
    </>
  );
};

export default CreateClinicianUsersComponent;

import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { Typography } from '@mui/material';
import React from 'react';

const Home = (): React.ReactElement => {
  return (
    <DashboardLayout title="Clinician Dashboard">
      <Typography variant="body1" component="span">
        {'You do not have permission to access this web application.'}
      </Typography>
    </DashboardLayout>
  );
};

export default Home;

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { CustomSortableTable, SortableTableRow } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { exactDateString, formatDate } from '@blumtechgroup/blum-ui-utils/dist/cjs/utils/dateUtils';
import { TbsaQueryResponse, selectTbsaResultsQuery } from './queries';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DateRangeFilteringOptions, dateRangeOptions, getEndDate, getStartDate } from '../common';

interface Props {
  organisationId?: string;
  locationId?: string;
}

interface SearchFilters {
  dateRange: DateRangeFilteringOptions;
}

const ListTbsaResultsComponent = ({ organisationId, locationId }: Props): React.ReactElement => {
  const [filters, setFilters] = useState<SearchFilters>({
    dateRange: DateRangeFilteringOptions.THIS_WEEK,
  });

  const getQueryVariables = (dateRange: DateRangeFilteringOptions) => {
    let variables = {
      filter: {
        _and: [
          {
            created_at: { _gte: exactDateString(getStartDate(dateRange)) },
          },
          {
            created_at: { _lte: exactDateString(getEndDate(dateRange)) },
          },
          ...(organisationId
            ? [
                {
                  organisation_id: { _eq: organisationId },
                },
              ]
            : []),
          ...(locationId
            ? [
                {
                  location_id: { _eq: locationId },
                },
              ]
            : []),
        ],
      },
    };

    return variables;
  };

  const { data: tbsaData, loading } = useQuery<TbsaQueryResponse>(selectTbsaResultsQuery, { fetchPolicy: 'no-cache', variables: getQueryVariables(filters.dateRange) });

  const rows: SortableTableRow[] =
    tbsaData?.tbsa_results?.map((item) => ({
      key: item.id,
      actions: [],
      columns: [
        {
          key: 'clinician_name',
          label: StringUtils.stringFromArray([item.clinician.first_name, item.clinician.last_name]),
        },
        {
          key: 'clinician_email',
          label: item.clinician.email,
        },
        {
          key: 'created_at',
          label: formatDate(new Date(item.created_at), 'DD/MM/yyyy'),
        },
        {
          key: 'tbsa',
          label: item.tbsa.toFixed(0),
        },
        {
          key: 'fluids',
          label: item.fluids.toFixed(0),
        },
        {
          key: 'age',
          label: item.age,
        },
        {
          key: 'weight',
          label: item.weight,
        },
      ],
    })) ?? [];

  const onDateRangeChanged = (value: string | null) => {
    setFilters((prev) => ({
      ...prev,
      dateRange: (value as DateRangeFilteringOptions) || DateRangeFilteringOptions.THIS_WEEK,
    }));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ToggleButtonGroup size="large" value={filters.dateRange} exclusive color="primary" onChange={(_: unknown, value: string | null) => onDateRangeChanged(value)}>
          {dateRangeOptions.map((i) => (
            <ToggleButton key={i.key} value={i.key}>
              {i.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <CustomSortableTable
          counter={rows.length}
          title={'TBSA'}
          headers={[
            {
              key: 'clinician_name',
              label: 'Clinician',
            },
            {
              key: 'clinician_email',
              label: 'Clinician email',
            },
            {
              key: 'created_at',
              label: 'Date',
            },
            {
              key: 'tbsa',
              label: 'TBSA (%)',
            },
            {
              key: 'fluids',
              label: 'Fluids (ml)',
            },
            {
              key: 'age',
              label: 'Age (y)',
            },
            {
              key: 'weight',
              label: 'Weight (kg)',
            },
          ]}
          loading={loading}
          rows={rows}
        />
      </Grid>
    </Grid>
  );
};

export default ListTbsaResultsComponent;

import { params, query, rawString, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

export const _requestedUserQuery = (email: string) => ({
  requested_users_by_pk: params(
    { email: rawString(email) },
    {
      email: types.string,
      first_name: types.string,
      last_name: types.string,
      organisation_name: types.string,
      location_name: types.string,
      is_approved: types.boolean,
    }
  ),
  organisations: [
    {
      id: types.string,
      name: types.string,
      locations: [
        {
          id: types.string,
          name: types.string,
        },
      ],
    },
  ],
});

type X = Types.Unpacked<typeof _requestedUserQuery>;

export type RequestedUser = X['requested_users_by_pk'];
export type Organisation = X['organisations'][0];

export const requestedUserQuery = (email: string) =>
  gql`
    ${query(_requestedUserQuery(email))}
  `;

import React, { useState } from 'react';
import { CustomViewToggle, CustomViewToggleViewType } from '@blumtechgroup/blum-react-core-components';
import { Search as SearchIcon, Clear as ClearSearchIcon } from '@mui/icons-material/';
import { Grid, GridSize, InputBase, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SearchContainer, SearchPaper, SearchIconContainer } from './styled';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { User, UserType } from './Common';
import TableViewComponent from './Common/components/TableView';
import GridViewComponent from './Common/components/GridView';

interface Props {
  users: User[];
  userType: string;
  userTypes?: UserType[];
  gridSize?: GridSize;
  tableTitle?: string;
  handleCreate: () => void;
  handleEdit: (id: string) => void;
  handleView: (id: string) => void;
}

const UsersList = ({ users, userType, userTypes = [], gridSize = 4, tableTitle = 'Users', handleCreate, handleEdit, handleView }: Props): React.ReactElement => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [usersFilter, setUsersFilter] = useState<string[]>([]);
  const [activeFiltered, setActiveFiltered] = useState<boolean>(true);

  const [viewMode, setViewMode] = useLocalStorage<CustomViewToggleViewType>(`${userType}_users_list_view_mode`, CustomViewToggleViewType.TABLE);

  return (
    <>
      <Grid container alignItems="center" style={{ marginBottom: '5px' }}>
        <Grid item xs={2}>
          {viewMode !== CustomViewToggleViewType.TABLE && (
            <Typography variant="h5" component="h2">
              {users.length} {tableTitle}
            </Typography>
          )}
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={2} justifyContent="right" alignItems="center">
            {viewMode !== CustomViewToggleViewType.TABLE && (
              <>
                <Grid item>
                  <SearchContainer>
                    {userTypes.length > 0 && (
                      <ToggleButtonGroup size="medium" value={usersFilter} color="primary" onChange={(_: unknown, values: string[]) => setUsersFilter(values)}>
                        {userTypes.map((i) => (
                          <ToggleButton key={i.user_type} value={i.user_type} disabled={users.filter((u) => u.user_type?.user_type === i.user_type).length === 0}>
                            {`${users.filter((u) => u.user_type?.user_type === i.user_type).length} ${i.description}`}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    )}
                    <SearchPaper variant="outlined">
                      <InputBase
                        sx={{ width: '100%' }}
                        placeholder="Search"
                        value={searchValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value || '')}
                        startAdornment={
                          <SearchIconContainer>
                            <SearchIcon />
                          </SearchIconContainer>
                        }
                        endAdornment={
                          <SearchIconContainer>
                            <ClearSearchIcon onClick={() => setSearchValue('')} />
                          </SearchIconContainer>
                        }
                        inputProps={{ 'aria-label': 'search list' }}
                      />
                    </SearchPaper>
                  </SearchContainer>
                </Grid>
                <Grid item>
                  <ToggleButtonGroup
                    color="primary"
                    value={activeFiltered ? 'active' : 'inactive'}
                    exclusive
                    onChange={(_event: React.MouseEvent<HTMLElement>, newAlignment: string) => setActiveFiltered(newAlignment === 'active')}
                    aria-label="Platform">
                    <ToggleButton value="active">Active</ToggleButton>
                    <ToggleButton value="inactive">Inactive</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </>
            )}
            <Grid item>
              <CustomViewToggle value={viewMode} handleChangeView={setViewMode} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {viewMode === CustomViewToggleViewType.TABLE ? (
          <Grid item xs={12}>
            <TableViewComponent
              users={users}
              tableTitle={tableTitle}
              activeFiltered={activeFiltered}
              setActiveFiltered={setActiveFiltered}
              handleCreate={handleCreate}
              handleEdit={handleEdit}
              handleView={handleView}
            />
          </Grid>
        ) : (
          <GridViewComponent
            users={users}
            gridSize={gridSize}
            searchValue={searchValue}
            usersFilter={usersFilter}
            activeFiltered={activeFiltered}
            handleCreate={handleCreate}
            handleEdit={handleEdit}
            handleView={handleView}
          />
        )}
      </Grid>
    </>
  );
};

export default UsersList;

import React, { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardLayout, CustomBackdrop } from '@blumtechgroup/blum-react-core-components';
import { adminUserReducer } from '../Common';
import Steps from '../Common/components/Steps';
import { insertAdminUserMutation, InsertAdminUserResponse } from './mutations';
import { useMutation } from '@apollo/client';

const CreateUser = (): React.ReactElement => {
  const navigate = useNavigate();
  const [user, dispatchUser] = useReducer(adminUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
  });

  const [createAdminUser, { loading }] = useMutation<InsertAdminUserResponse>(insertAdminUserMutation);

  const handleSave = async () => {
    const createVariables = {
      object: user,
    };
    const { data } = await createAdminUser({ variables: createVariables });
    if (data) {
      handleFinish(data.insert_users_admin_one.id);
    }
  };

  const handleFinish = (id: string) => {
    navigate(`/admin/users/${id}`);
  };

  return (
    <>
      <DashboardLayout breadcrumbs={[{ label: 'Admin', link: '/admin/home' }, { label: 'Users', link: '/admin/users' }, { label: 'Create' }]}>
        <Steps completeLabel="Create" user={user} isEdit={false} dispatch={dispatchUser} handleSave={handleSave} />
      </DashboardLayout>
      {loading && <CustomBackdrop label="Creating User" />}
    </>
  );
};

export default CreateUser;

import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _editClinicianUserInitQuery = (userId: string) => ({
  users_clinician_by_pk: params(
    { id: rawString(userId) },
    {
      first_name: types.string,
      last_name: types.string,
      location: {
        name: types.string,
        organisation: {
          name: types.string,
        },
      },
    }
  ),
});

type X = Types.Unpacked<typeof _editClinicianUserInitQuery>;
export type Clinician = X['users_clinician_by_pk'];

export const editClinicianUserInitQuery = (userId: string) =>
  gql`
    ${query(_editClinicianUserInitQuery(userId))}
  `;

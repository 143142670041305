import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _createClinicianUserInitQuery = (locationId?: string) => ({
  locations: params(
    {
      offset: 0,
      ...(locationId
        ? {
            where: {
              id: { _eq: rawString(locationId) },
            },
          }
        : {}),
    },
    [
      {
        license_type_assignments: [
          {
            count: types.number,
            license_type: {
              id: types.string,
              name: types.string,
            },
          },
        ],
        clinician_license_type_assignments: [
          {
            license_type_id: types.string,
          },
        ],
      },
    ]
  ),
});

type X = Types.Unpacked<typeof _createClinicianUserInitQuery>;
export type Location = X['locations'][0];
export type LicenseType = X['locations'][0]['license_type_assignments'][0];

export const createClinicianUserInitQuery = (locationId?: string) =>
  gql`
    ${query(_createClinicianUserInitQuery(locationId))}
  `;

export const createClinicianUserQuery = gql`
  query ($licenseTypeId: uuid!) {
    clinician_license_type_assignments_aggregate(where: { license_type_id: { _eq: $licenseTypeId } }) {
      aggregate {
        count
      }
    }
    location_license_type_assignments(where: { license_type_id: { _eq: $licenseTypeId } }) {
      count
    }
  }
`;

export const createClinicianUserForLocationQuery = gql`
  query ($licenseTypeId: uuid!, $locationId: uuid!) {
    clinician_license_type_assignments_aggregate(where: { _and: [{ license_type_id: { _eq: $licenseTypeId } }, { location_id: { _eq: $locationId } }] }) {
      aggregate {
        count
      }
    }
    location_license_type_assignments(where: { _and: [{ license_type_id: { _eq: $licenseTypeId } }, { location_id: { _eq: $locationId } }] }) {
      count
    }
  }
`;

export interface CreateClinicianUserVariables {
  licenseTypeId: string;
  locationId?: string;
}

export interface CreateClinicianUserResponse {
  clinician_license_type_assignments_aggregate: {
    aggregate: {
      count: number;
    };
  };
  location_license_type_assignments: { count: number }[];
}

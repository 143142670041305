import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectCliniciansInitQuery = (locationId?: string) => ({
  users_clinician: params(
    {
      offset: 0,
      ...(locationId
        ? {
            where: {
              location_id: {
                _eq: rawString(locationId),
              },
            },
          }
        : {}),
    },
    [
      {
        id: types.string,
        first_name: types.string,
        last_name: types.string,
        email: types.string,
        active: types.boolean,
        license: {
          license_type: {
            name: types.string,
          },
        },
      },
    ]
  ),
  location_license_type_assignments: params(
    {
      offset: 0,
      ...(locationId
        ? {
            where: { location_id: { _eq: rawString(locationId) } },
          }
        : {}),
    },
    [
      {
        license_type: {
          name: types.string,
        },
      },
    ]
  ),
});

type X = Types.Unpacked<typeof _selectCliniciansInitQuery>;
export type Clinician = X['users_clinician'][0];
export type LicenseType = X['location_license_type_assignments'][0];

export const selectCliniciansInitQuery = (locationId?: string) =>
  gql`
    ${query(_selectCliniciansInitQuery(locationId))}
  `;

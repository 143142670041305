import { gql } from '@apollo/client';

export const insertLicenseTypeMutation = gql`
  mutation ($object: license_types_insert_input!) {
    insert_license_types_one(object: $object) {
      id
    }
  }
`;

export interface InsertLicenseTypeResponse {
  insert_license_types_one: {
    id: string;
  };
}

import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { useQuery } from '@apollo/client';
import { selectOrganisationUsersInitQuery, OrganisationUsersPageState } from './queries';
import UsersList from '../../../../../components/Global/Users/List';

interface Props {
  organisationId: string;
  handleView: (userId: string) => void;
  handleEdit: (userId: string) => void;
  handleCreate: () => void;
}

const ListOrganisationUsersComponent = ({ organisationId, handleView, handleEdit, handleCreate }: Props): React.ReactElement => {
  const [pageState, setPageState] = useState<OrganisationUsersPageState>({
    users_organisation_admins: [],
  });
  const { data: organisationsInitData } = useQuery(selectOrganisationUsersInitQuery(organisationId), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (organisationsInitData) {
      setPageState(organisationsInitData.organisations_by_pk);
    }
  }, [organisationsInitData]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <UsersList
          users={pageState.users_organisation_admins.map((item) => ({
            id: item.id,
            full_name: StringUtils.stringFromArray([item.first_name, item.last_name]),
            email: item.email,
            active: item.active,
          }))}
          userType="organisation"
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          handleView={handleView}
        />
      </Grid>
    </Grid>
  );
};

export default ListOrganisationUsersComponent;

import React, { useState, useEffect } from 'react';
import { ReadOnlyBlock } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { Grid } from '@mui/material';
import { useQuery } from '@apollo/client';
import { selectLocationUserInitQuery, LocationUser } from './queries';

interface Props {
  userId: string;
  handleEdit: () => void;
}

const ViewLocationUsersComponent = ({ userId, handleEdit }: Props): React.ReactElement => {
  const [user, setUser] = useState<LocationUser>({
    first_name: '',
    last_name: '',
    email: '',
    active: true,
  });

  const { data: locationUserInitData } = useQuery(selectLocationUserInitQuery(userId), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationUserInitData) {
      setUser(locationUserInitData.users_location_admin_by_pk);
    }
  }, [locationUserInitData]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <ReadOnlyBlock
          items={[
            { label: 'Full Name', value: StringUtils.stringFromArray([user.first_name, user.last_name]) },
            { label: 'Email', value: user.email },
            { label: 'Active', value: StringUtils.booleanToYesNo(user.active) },
          ]}
          handleEdit={handleEdit}
        />
      </Grid>
    </Grid>
  );
};

export default ViewLocationUsersComponent;

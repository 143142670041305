import { types, query, params, rawString } from 'typed-graphqlify';
import { Types } from '@blumtechgroup/blum-ui-utils';
import { gql } from '@apollo/client';

const _editOrganisationInitQuery = (organisationId: string) => ({
  organisations_by_pk: params(
    { id: rawString(organisationId) },
    {
      id: types.string,
      name: types.string,
      active: types.boolean,
      premium: types.boolean,
      license_type_assignments: [
        {
          organisation_id: types.string,
          license_type_id: types.string,
          count: types.number,
        },
      ],
    }
  ),
  license_types: [
    {
      id: types.string,
      name: types.string,
    },
  ],
});

type X = Types.Unpacked<typeof _editOrganisationInitQuery>;
export type LicenseType = X['license_types'][0];

export const editOrganisationInitQuery = (organisationId: string) => gql`
  ${query(_editOrganisationInitQuery(organisationId))}
`;

export const getAllocatedLicensesQuery = gql`
  query ($organisationId: uuid!, $licenseTypeIds: [uuid]!) {
    location_license_type_assignments(where: { _and: [{ organisation_id: { _eq: $organisationId } }, { license_type_id: { _in: $licenseTypeIds } }] }) {
      license_type_id
      count
    }
  }
`;

export interface AllocatedLicensesVariables {
  organisationId: string;
  licenseTypeIds: string[];
}

export interface AllocatedLicensesResponse {
  location_license_type_assignments: { license_type_id: string; count: number }[];
}

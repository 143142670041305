import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import UsersList from '../../../../components/Global/Users/List';
import { selectAdminUsersInitQuery, AdminUser } from './queries';

const ListOrganisationUsers = (): React.ReactElement => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<AdminUser[]>([]);

  const { data: adminUserInitQuery } = useQuery(selectAdminUsersInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (adminUserInitQuery) {
      const { users_admin } = adminUserInitQuery;
      setUsers(users_admin);
    }
  }, [adminUserInitQuery]);

  const handleCreate = () => {
    navigate(`/admin/users/create`);
  };

  const handleView = (id: string) => {
    navigate(`/admin/users/${id}`);
  };

  const handleEdit = (id: string) => {
    navigate(`/admin/users/${id}/edit`);
  };

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Admin', link: '/admin/home' }, { label: 'Users' }]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <UsersList users={users} userType="Admin" handleCreate={handleCreate} handleEdit={handleEdit} handleView={handleView} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ListOrganisationUsers;

import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _createOrganisationInitQuery = () => ({
  license_types: [
    {
      id: types.string,
      name: types.string,
    },
  ],
});

type X = Types.Unpacked<typeof _createOrganisationInitQuery>;
export type LicenseType = X['license_types'][0];

export const createOrganisationInitQuery = () =>
  gql`
    ${query(_createOrganisationInitQuery())}
  `;

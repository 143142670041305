import { gql } from '@apollo/client';

export const updateLocationUserMutation = gql`
  mutation ($pk_columns: users_location_admin_pk_columns_input!, $set: users_location_admin_set_input!) {
    update_users_location_admin_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export interface UpdateLocationUserResponse {
  update_users_location_admin_by_pk: {
    id: string;
  };
}

import { GridCard, GridActionCard } from '@blumtechgroup/blum-react-core-components';
import { GridSize } from '@mui/material';
import { User } from '../..';

interface Props {
  users: User[];
  gridSize?: GridSize;
  searchValue: string;
  usersFilter: string[];
  activeFiltered: boolean;
  handleCreate: () => void;
  handleEdit: (id: string) => void;
  handleView: (id: string) => void;
}

const GridViewComponent = ({ users, gridSize, searchValue, usersFilter, activeFiltered, handleCreate, handleEdit, handleView }: Props) => {
  return (
    <>
      <GridActionCard gridSize={gridSize} fill={true} onClick={handleCreate} />
      {users
        .filter((i) => i.full_name.toLowerCase().includes(searchValue.toLowerCase()))
        .filter((i) => i.active === activeFiltered)
        .filter((i) => (usersFilter.length > 0 ? usersFilter.includes(i.user_type?.user_type || '') : true))
        .map((user) => (
          <GridCard
            fill={true}
            key={user.id}
            gridSize={gridSize}
            title={user.full_name}
            subTitle={user.user_type?.description}
            description={user.email}
            handleEdit={() => handleEdit(user.id)}
            handleView={() => handleView(user.id)}
          />
        ))}
    </>
  );
};

export default GridViewComponent;

import React, { useState, useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DashboardLayout, CustomBackdrop, CustomDialog } from '@blumtechgroup/blum-react-core-components';
import { LicenseType, organisationReducer, LicenseTypeAssignment, OrganisationReducerAction } from '../Common';
import Steps from '../Common/components/Steps';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { AllocatedLicensesResponse, AllocatedLicensesVariables, editOrganisationInitQuery, getAllocatedLicensesQuery } from './queries';
import { updateOrganisationMutation } from './mutations';

type UrlParams = {
  organisation_id: string;
};

const OrganisationEdit = (): React.ReactElement => {
  const navigate = useNavigate();
  const { organisation_id } = useParams<UrlParams>();
  const [organisation, dispatchOrganisation] = useReducer(organisationReducer, {
    name: '',
    active: true,
    premium: false,
    license_type_assignments: [],
  });

  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);

  const [saving, setSaving] = useState<boolean>(false);
  const [updateDenied, setUpdateDenied] = useState<boolean>(false);
  const [deleteDenied, setDeleteDenied] = useState<boolean>(false);

  const { data: editOrganisationInitQueryData } = useQuery(editOrganisationInitQuery(organisation_id!), { fetchPolicy: 'no-cache' });
  const [updateOrganisation] = useMutation(updateOrganisationMutation);
  const [getAllocatedLicenses] = useLazyQuery<AllocatedLicensesResponse, AllocatedLicensesVariables>(getAllocatedLicensesQuery);

  useEffect(() => {
    if (editOrganisationInitQueryData) {
      const { organisations_by_pk: organisation, license_types } = editOrganisationInitQueryData;
      dispatchOrganisation({ type: OrganisationReducerAction.INIT, value: organisation });
      setLicenseTypes(license_types);
    }
  }, [editOrganisationInitQueryData]);

  const handleSave = async () => {
    setSaving(true);

    const { license_type_assignments, ...set } = organisation;

    const { organisations_by_pk: originalOrganisation } = editOrganisationInitQueryData;

    const insertLicenseTypes = license_type_assignments.map((item) => ({
      organisation_id,
      license_type_id: item.license_type_id,
      count: item.count,
    }));

    const deleteLicenseTypes: string[] = originalOrganisation.license_type_assignments
      .filter((item: LicenseTypeAssignment) => !organisation.license_type_assignments.some((l) => l.license_type_id === item.license_type_id))
      .map((item: LicenseTypeAssignment) => item.license_type_id);

    const { data } = await getAllocatedLicenses({
      variables: {
        licenseTypeIds: [...insertLicenseTypes.map((item) => item.license_type_id), ...deleteLicenseTypes],
        organisationId: organisation_id!,
      },
    });

    const allocatedLicenses = data ? data.location_license_type_assignments : [];

    const updateNotAllowed = insertLicenseTypes.some(
      (item) =>
        allocatedLicenses
          .filter((i) => i.license_type_id === item.license_type_id)
          .map((item) => item.count)
          .reduce((t, i) => t + i, 0) > item.count
    );

    setUpdateDenied(updateNotAllowed);
    if (updateNotAllowed) {
      setSaving(false);

      return;
    }

    const deleteNotAllowed = deleteLicenseTypes.some((item) => allocatedLicenses.filter((i) => i.license_type_id === item).length);

    setDeleteDenied(deleteNotAllowed);

    if (deleteNotAllowed) {
      setSaving(false);

      return;
    }

    const updateVariables = {
      pk_columns: {
        id: organisation_id,
      },
      set: set,
      license_types: insertLicenseTypes,
      delete_license_types: deleteLicenseTypes,
      organisation_id: organisation_id,
    };

    await updateOrganisation({ variables: updateVariables });

    setSaving(false);
    handleFinish();
  };

  const handleFinish = () => {
    navigate(`/admin/organisations/${organisation_id}`);
  };

  return (
    <>
      <DashboardLayout
        breadcrumbs={[
          { label: 'Admin', link: '/admin/home' },
          { label: 'Organisations', link: '/admin/organisations' },
          { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
          { label: 'Edit' },
        ]}>
        <Steps completeLabel="Save" organisation={organisation} licenseTypes={licenseTypes} dispatch={dispatchOrganisation} handleSave={handleSave} />
      </DashboardLayout>
      {saving && <CustomBackdrop label="Saving Changes" />}
      <CustomDialog
        open={deleteDenied}
        title={'Update organisation'}
        subtitle={'Unable to unassign one or more license types.'}
        message={'Please, make sure that are no locations assigned to the selected license type(s).'}
        actions={[
          {
            label: 'Close',
            onClick: () => setDeleteDenied(false),
          },
        ]}
        handleClose={() => setDeleteDenied(false)}
      />
      <CustomDialog
        open={updateDenied}
        title={'Update organisation'}
        subtitle={'Unable to decrease a quota for one or more license types.'}
        message={'Please, make sure that the number of licenses more than or equal to the number of already assigned licenses.'}
        actions={[
          {
            label: 'Close',
            onClick: () => setUpdateDenied(false),
          },
        ]}
        handleClose={() => setUpdateDenied(false)}
      />
    </>
  );
};

export default OrganisationEdit;

import React, { useReducer } from 'react';
import { CustomBackdrop } from '@blumtechgroup/blum-react-core-components';
import { locationUserReducer } from '../Common';
import Steps from '../Common/components/Steps';
import { insertLocationUserMutation, InsertLocationUserResponse } from './mutations';
import { useMutation } from '@apollo/client';

interface Props {
  organisationId?: string;
  locationId?: string;
  handleFinish: (userId: string) => void;
}

const CreateLocationUsersComponent = ({ organisationId, locationId, handleFinish }: Props): React.ReactElement => {
  const [user, dispatchUser] = useReducer(locationUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
  });

  const [createLocationUser, { loading }] = useMutation<InsertLocationUserResponse>(insertLocationUserMutation);

  const handleSave = async () => {
    const createVariables = {
      object: {
        ...user,
        organisation_id: organisationId,
        location_id: locationId,
      },
    };

    const { data } = await createLocationUser({ variables: createVariables });

    if (data) {
      const {
        insert_users_location_admin_one: { id: userId },
      } = data;

      handleFinish(userId);
    }
  };

  return (
    <>
      <Steps completeLabel="Create" isEdit={false} user={user} dispatch={dispatchUser} handleSave={handleSave} />
      {loading && <CustomBackdrop label="Creating User" />}
    </>
  );
};

export default CreateLocationUsersComponent;

import React from 'react';
import { ReadOnlyBlock } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { ClinicianUser } from '../..';
import { LicenseType } from '../../../Create/queries';

interface Props {
  user: ClinicianUser;
  licenseTypes: LicenseType[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ user, licenseTypes, setStep }: Props): React.ReactElement => {
  return (
    <ReadOnlyBlock
      items={[
        { label: 'Full Name', value: StringUtils.stringFromArray([user.first_name, user.last_name]) },
        { label: 'Email', value: user.email },
        { label: 'Active', value: StringUtils.booleanToYesNo(user.active) },
        {
          label: 'License Type',
          value: user.license ? licenseTypes.find((item) => item.license_type.id === user.license?.license_type_id)?.license_type.name : 'No license assigned',
        },
      ]}
      handleEdit={() => setStep(0)}
    />
  );
};

export default StepReview;

import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _editLicenseTypeInitQuery = (licenseTypeId: string) => ({
  license_types_by_pk: params(
    { id: rawString(licenseTypeId) },
    {
      name: types.string,
    }
  ),
});

export const editLicenseTypeInitQuery = (licenseTypeId: string) =>
  gql`
    ${query(_editLicenseTypeInitQuery(licenseTypeId))}
  `;

import React, { useCallback } from 'react';
import { Types, ValidatorsUtils } from '@blumtechgroup/blum-ui-utils';
import { FormControl, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { ClinicianUser, ValidationErrors, validationErrors, ClinicianUserReducerAction } from '../..';

interface Props {
  user: ClinicianUser;
  errors: ValidationErrors;
  isEdit: boolean;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: ClinicianUserReducerAction; value: any }>;
}

const StepDetails = ({ user, errors, isEdit, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: Types.UnknownObject = {};
      if (field === 'first_name') {
        errorItems.first_name = !user.first_name ? validationErrors.first_name : false;
      }
      if (field === 'last_name') {
        errorItems.last_name = !user.last_name ? validationErrors.last_name : false;
      }
      if (field === 'email') {
        errorItems.email = !(user.email && ValidatorsUtils.validateEmailAddress(user.email)) ? validationErrors.email : false;
      }
      setErrors({ ...errors, ...errorItems });
    },
    [user, errors, setErrors]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          sx={{ width: '100%' }}
          data-qa="first-name-textfield"
          required
          label="First name"
          variant="outlined"
          error={errors.first_name as boolean}
          helperText={errors.first_name}
          value={user.first_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ClinicianUserReducerAction.FIRST_NAME, value: event.target.value })}
          onKeyUp={() => validate('first_name')}
          onBlur={() => validate('first_name')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          sx={{ width: '100%' }}
          data-qa="last-name-textfield"
          required
          label="Last name"
          variant="outlined"
          error={errors.last_name as boolean}
          helperText={errors.last_name}
          value={user.last_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ClinicianUserReducerAction.LAST_NAME, value: event.target.value })}
          onKeyUp={() => validate('last_name')}
          onBlur={() => validate('last_name')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          sx={{ width: '100%' }}
          data-qa="email-textfield"
          required
          label="Email"
          disabled={isEdit}
          variant="outlined"
          value={user.email}
          error={errors.email as boolean}
          helperText={errors.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ClinicianUserReducerAction.EMAIL, value: event.target.value })}
          onKeyUp={() => validate('email')}
          onBlur={() => validate('email')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormControlLabel
            label="Active"
            control={
              <Switch
                checked={user.active}
                color="primary"
                data-qa="active-toggle"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: ClinicianUserReducerAction.ACTIVE,
                    value: event.target.checked,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default StepDetails;

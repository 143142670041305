import React, { useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DashboardLayout, CustomBackdrop } from '@blumtechgroup/blum-react-core-components';
import { licenseTypeReducer, LicenseTypeReducerAction } from '../Common';
import Steps from '../Common/components/Steps';
import { useMutation, useQuery } from '@apollo/client';
import { editLicenseTypeInitQuery } from './queries';
import { updateLicenseTypeMutation } from './mutations';

type UrlParams = {
  license_type_id: string;
};

const EditLicenseTypes = (): React.ReactElement => {
  const navigate = useNavigate();
  const { license_type_id } = useParams<UrlParams>();
  const [licenseType, dispatchLicenseType] = useReducer(licenseTypeReducer, {
    name: '',
  });

  const { data: editLicenseTypeInitQueryData } = useQuery(editLicenseTypeInitQuery(license_type_id!), { fetchPolicy: 'no-cache' });
  const [updateLicenseType, { loading }] = useMutation(updateLicenseTypeMutation);

  useEffect(() => {
    if (editLicenseTypeInitQueryData) {
      const { license_types_by_pk } = editLicenseTypeInitQueryData;
      dispatchLicenseType({ type: LicenseTypeReducerAction.INIT, value: license_types_by_pk });
    }
  }, [editLicenseTypeInitQueryData]);

  const handleSave = async () => {
    const updateVariables = {
      pk_columns: {
        id: license_type_id,
      },
      set: licenseType,
    };

    await updateLicenseType({ variables: updateVariables });

    handleFinish();
  };

  const handleFinish = () => {
    navigate(`/admin/license-types/${license_type_id}`);
  };

  return (
    <>
      <DashboardLayout
        breadcrumbs={[
          { label: 'Admin', link: '/admin/home' },
          { label: 'License Types', link: '/admin/license-types' },
          { label: licenseType.name, link: `/admin/license-types/${license_type_id}` },
          { label: 'Edit' },
        ]}>
        <Steps completeLabel="Save" licenseType={licenseType} dispatch={dispatchLicenseType} handleSave={handleSave} />
      </DashboardLayout>
      {loading && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default EditLicenseTypes;

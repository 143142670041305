import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { CreateLocationUsersComponent } from '../../../../components/Global';

const CreateLocationUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const handleFinish = (userId: string) => {
    navigate(`/loc/users/${userId}`);
  };

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Home', link: '/loc/home' }, { label: 'Users', link: `/loc/users` }, { label: 'Create' }]}>
      <CreateLocationUsersComponent handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default CreateLocationUsers;

import React from 'react';
import { ReadOnlyBlock } from '@blumtechgroup/blum-react-core-components';
import { LicenseType, Organisation } from '../..';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';

interface Props {
  organisation: Organisation;
  licenseTypes: LicenseType[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ organisation, licenseTypes, setStep }: Props): React.ReactElement => (
  <ReadOnlyBlock
    items={[
      { label: 'Name', value: organisation.name },
      { label: 'Active', value: StringUtils.booleanToYesNo(organisation.active) },
      { label: 'Premium', value: StringUtils.booleanToYesNo(organisation.premium) },
      {
        label: 'Licenses',
        list: organisation.license_type_assignments.length
          ? organisation.license_type_assignments.map((item) => `${licenseTypes.find((i) => i.id === item.license_type_id)?.name} - ${item.count}`)
          : undefined,
        value: organisation.license_type_assignments.length ? undefined : 'No licenses assigned',
      },
    ]}
    handleEdit={() => setStep(0)}
  />
);

export default StepReview;

import { query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

export const _requestedUsersQuery = () => ({
  requested_users: [
    {
      email: types.string,
      first_name: types.string,
      last_name: types.string,
      organisation_name: types.string,
      location_name: types.string,
      is_approved: types.boolean,
    },
  ],
});

export type RequestedUsersPageState = Types.Unpacked<typeof _requestedUsersQuery>['requested_users'][0];

export const requestedUsersQuery = () =>
  gql`
    ${query(_requestedUsersQuery())}
  `;

import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import AuthProvider, { AuthContext } from '../AuthProvider';
import AppRoutes from '../../Routes';
import { CustomThemeProvider, ThemeModes, DashboardProvider } from '@blumtechgroup/blum-react-core-components';

import useLocalStorage from '../../hooks/useLocalStorage';
import { withRouter, listItems } from '../DashboardProviderArgs';
import ApolloWrapper from '../ApolloProvider';
import CognitoProvider from '../CognitoProvider';
const modes: ThemeModes = {
  //TODO change theme colours
  primary: {
    main: '#00CCCC',
    light: '#00CCCC70',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#FFCE00',
    light: '#FFCE0070',
    contrastText: '#FFFFFF',
  },
  light: {
    background: {
      paper: '#FFFFFF',
      default: '#F4F4F4',
    },
    text: {
      primary: '#000000DE',
      secondary: '#000000D2',
    },
  },
  dark: {
    background: {
      paper: '#222B36',
      default: '#171C24',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFFB3',
    },
  },
};

const Root = styled('div')(() => ({ position: 'absolute', width: '100%', height: '100%', fontFamily: 'Roboto' }));

const App = () => {
  return (
    <CognitoProvider>
      <ApolloWrapper>
        <AuthProvider>
          <CustomThemeProvider storageProvider={useLocalStorage} modes={modes}>
            <DashboardProvider
              AuthContext={AuthContext}
              withRouter={withRouter}
              storageProvider={useLocalStorage}
              dashboardItems={listItems}
              loginFallback={
                <Typography variant="subtitle1" component="span">
                  {
                    'Sorry, but you are no longer able to access this website or you are trying to use a user with the role that is acceptable only by mobile app. If you think this is a mistake please get in touch with an administrator.'
                  }
                </Typography>
              }>
              <CssBaseline />
              <Root>
                <BrowserRouter>
                  <AppRoutes />
                </BrowserRouter>
              </Root>
            </DashboardProvider>
          </CustomThemeProvider>
        </AuthProvider>
      </ApolloWrapper>
    </CognitoProvider>
  );
};

export default App;

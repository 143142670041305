import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectOrganisationUsersInitQuery = (organisationId: string) => ({
  organisations_by_pk: params(
    { id: rawString(organisationId) },
    {
      users_organisation_admins: [
        {
          id: types.string,
          first_name: types.string,
          last_name: types.string,
          email: types.string,
          active: types.boolean,
        },
      ],
    }
  ),
});

type X = Types.Unpacked<typeof _selectOrganisationUsersInitQuery>;
export type OrganisationUsersPageState = X['organisations_by_pk'];

export const selectOrganisationUsersInitQuery = (organisationId: string) =>
  gql`
    ${query(_selectOrganisationUsersInitQuery(organisationId))}
  `;

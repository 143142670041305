import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _editClinicianUserInitQuery = (userId: string) => ({
  users_clinician_by_pk: params(
    { id: rawString(userId) },
    {
      first_name: types.string,
      last_name: types.string,
      email: types.string,
      active: types.boolean,
      location: {
        license_type_assignments: [
          {
            count: types.number,
            license_type: {
              id: types.string,
              name: types.string,
            },
          },
        ],
        clinician_license_type_assignments: [
          {
            clinician_id: types.string,
            license_type_id: types.string,
          },
        ],
      },
      license: {
        license_type_id: types.string,
      },
    }
  ),
});

type X = Types.Unpacked<typeof _editClinicianUserInitQuery>;
export type Location = X['users_clinician_by_pk']['location'];

export const editClinicianUserInitQuery = (userId: string) =>
  gql`
    ${query(_editClinicianUserInitQuery(userId))}
  `;

export const getActiveUsersQuery = gql`
  query ($clinicianId: uuid!, $licenseTypeId: uuid!) {
    clinician_license_type_assignments_aggregate(where: { _and: [{ clinician_id: { _neq: $clinicianId } }, { license_type_id: { _eq: $licenseTypeId } }] }) {
      aggregate {
        count
      }
    }
    location_license_type_assignments(where: { _and: [{ license_type_id: { _eq: $licenseTypeId } }] }) {
      count
    }
  }
`;

export const getActiveUsersForLocationQuery = gql`
  query ($clinicianId: uuid!, $licenseTypeId: uuid!, $locationId: uuid!) {
    clinician_license_type_assignments_aggregate(
      where: { _and: [{ clinician_id: { _neq: $clinicianId } }, { license_type_id: { _eq: $licenseTypeId } }, { location_id: { _eq: $locationId } }] }
    ) {
      aggregate {
        count
      }
    }
    location_license_type_assignments(where: { _and: [{ license_type_id: { _eq: $licenseTypeId } }, { location_id: { _eq: $locationId } }] }) {
      count
    }
  }
`;

export interface GetActiveUsersVariables {
  clinicianId: string;
  licenseTypeId: string;
  locationId?: string;
}

export interface GetActiveUsersResponse {
  clinician_license_type_assignments_aggregate: {
    aggregate: {
      count: number;
    };
  };
  location_license_type_assignments: { count: number }[];
}

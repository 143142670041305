import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const Modal = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '0 0 auto',
  width: theme.spacing(48),
}));

export const LogoContainer = styled('div')(({ theme }) => ({
  width: theme.spacing(30),
  alignSelf: 'center',
}));

export const ProgressContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(3),
  flexDirection: 'column',
  flex: '1 1 auto',
  justifyContent: 'flex-end',
  minHeight: theme.spacing(5),
  '& button': {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  '& button:last-child': {
    marginBottom: 0,
  },
}));

export const Password = styled(TextField)(({ theme }) => ({
  marginTop: `${theme.spacing(2)} !important`,
  marginBottom: `${theme.spacing(1)} !important`,
}));

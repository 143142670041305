import { getDaysPast } from '@blumtechgroup/blum-ui-utils/dist/cjs/utils/dateUtils';
import { getMonthsPast } from '@blumtechgroup/blum-ui-utils/dist/cjs/utils/dateUtils';

export enum DateRangeFilteringOptions {
  THIS_WEEK = 'THIS_WEEK',
  MONTH = 'MONTH',
  TWO_MONTHS = 'TWO_MONTHS',
}

export const dateRangeOptions = [
  { key: DateRangeFilteringOptions.THIS_WEEK, label: 'This week' },
  { key: DateRangeFilteringOptions.MONTH, label: 'This Month' },
  { key: DateRangeFilteringOptions.TWO_MONTHS, label: 'Two months' },
];

export const getStartDate = (dateRange: DateRangeFilteringOptions): Date => {
  let date = new Date();

  if (dateRange === DateRangeFilteringOptions.THIS_WEEK) {
    date = getDaysPast(7);
  }

  if (dateRange === DateRangeFilteringOptions.MONTH) {
    date = getMonthsPast(1);
  }

  if (dateRange === DateRangeFilteringOptions.TWO_MONTHS) {
    date = getMonthsPast(2);
  }

  date.setHours(0, 0, 0);

  return date;
};

export const getEndDate = (_: DateRangeFilteringOptions): Date => {
  let date = new Date();

  date.setHours(23, 59, 59);

  return date;
};

import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectLicenseTypeInitQuery = (organisationId: string) => ({
  license_types_by_pk: params(
    { id: rawString(organisationId) },
    {
      name: types.string,
      organisation_assignments: [
        {
          count: types.number,
          organisation: {
            id: types.string,
            name: types.string,
          },
        },
      ],
    }
  ),
});

type X = Types.Unpacked<typeof _selectLicenseTypeInitQuery>;
export type LicenseType = X['license_types_by_pk'];

export const selectLicenseTypeInitQuery = (organisationId: string) =>
  gql`
    ${query(_selectLicenseTypeInitQuery(organisationId))}
  `;

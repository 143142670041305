import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectLocationsInitQuery = () => ({
  organisations: [
    {
      id: types.string,
      name: types.string,
    },
  ],
});

type X = Types.Unpacked<typeof _selectLocationsInitQuery>;
export type Organisation = X['organisations'][0];

export const selectLocationsInitQuery = () =>
  gql`
    ${query(_selectLocationsInitQuery())}
  `;

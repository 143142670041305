import { ApolloProvider } from '@apollo/client';
import { ApolloClientUtils } from '@blumtechgroup/blum-ui-utils';
import { useCallback, useContext } from 'react';
import { CognitoContext } from '../CognitoProvider';

const { REACT_APP_API_URL: API_URL } = process.env;

interface Props {
  children?: React.ReactNode;
}

const ApolloWrapper = ({ children }: Props) => {
  const cognitoContext = useContext(CognitoContext);
  if (cognitoContext === null) {
    throw new Error('No AuthContext');
  }

  const { token } = cognitoContext;

  const tokenProvider = useCallback(() => token ?? '', [token]);

  const apolloClient = ApolloClientUtils.createApolloClient(API_URL ?? '', tokenProvider);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloWrapper;

import React from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';

const Logo = (): React.ReactElement => {
  const [darkMode] = useLocalStorage<boolean>('dark-mode', false);

  const textColour = darkMode ? '#FFFFFF' : '#020202';

  return (
    <svg id="LOGO" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 284 90">
      <path style={{ fill: textColour }} d="M37.7 38.3C41.4333 38.9667 44.6 40.9333 47.2 44.2C49.8 47.4667 51.1 51.1667 51.1 55.3C51.1 58.8333 50.1667 62.0333 48.3 64.9C46.5 67.7 43.8667 69.9333 40.4 71.6C36.9333 73.2 32.9 74 28.3 74H0.5V4.5H27C31.7333 4.5 35.8 5.3 39.2 6.9C42.6 8.5 45.1667 10.6667 46.9 13.4C48.6333 16.0667 49.5 19.0667 49.5 22.4C49.5 26.4 48.4333 29.7333 46.3 32.4C44.1667 35.0667 41.3 37.0333 37.7 38.3ZM11.9 33.7H26C29.7333 33.7 32.6333 32.8667 34.7 31.2C36.8333 29.4667 37.9 27 37.9 23.8C37.9 20.6667 36.8333 18.2333 34.7 16.5C32.6333 14.7 29.7333 13.8 26 13.8H11.9V33.7ZM27.3 64.7C31.1667 64.7 34.2 63.7667 36.4 61.9C38.6 60.0333 39.7 57.4333 39.7 54.1C39.7 50.7 38.5333 48 36.2 46C33.8667 44 30.7667 43 26.9 43H11.9V64.7H27.3Z" />
      <path style={{ fill: textColour }} d="M74.8883 0V74H63.4883V0H74.8883Z" />
      <path style={{ fill: textColour }} d="M139.755 18.9V74H128.355V67.5C126.555 69.7667 124.189 71.5667 121.255 72.9C118.389 74.1667 115.322 74.8 112.055 74.8C107.722 74.8 103.822 73.9 100.355 72.1C96.9555 70.3 94.2555 67.6333 92.2555 64.1C90.3221 60.5667 89.3555 56.3 89.3555 51.3V18.9H100.655V49.6C100.655 54.5333 101.889 58.3333 104.355 61C106.822 63.6 110.189 64.9 114.455 64.9C118.722 64.9 122.089 63.6 124.555 61C127.089 58.3333 128.355 54.5333 128.355 49.6V18.9H139.755ZM106.255 12C104.589 12 103.155 11.4333 101.955 10.3C100.822 9.1 100.255 7.66667 100.255 6C100.255 4.33333 100.822 2.93333 101.955 1.79999C103.155 0.599998 104.589 0 106.255 0C107.922 0 109.322 0.599998 110.455 1.79999C111.655 2.93333 112.255 4.33333 112.255 6C112.255 7.66667 111.655 9.1 110.455 10.3C109.322 11.4333 107.922 12 106.255 12ZM123.455 12C121.789 12 120.355 11.4333 119.155 10.3C118.022 9.1 117.455 7.66667 117.455 6C117.455 4.33333 118.022 2.93333 119.155 1.79999C120.355 0.599998 121.789 0 123.455 0C125.122 0 126.522 0.599998 127.655 1.79999C128.855 2.93333 129.455 4.33333 129.455 6C129.455 7.66667 128.855 9.1 127.655 10.3C126.522 11.4333 125.122 12 123.455 12Z" />
      <path style={{ fill: textColour }} d="M221.497 18C225.83 18 229.697 18.9 233.097 20.7C236.564 22.5 239.264 25.1667 241.197 28.7C243.197 32.2333 244.197 36.5 244.197 41.5V74H232.897V43.2C232.897 38.2667 231.664 34.5 229.197 31.9C226.73 29.2333 223.364 27.9 219.097 27.9C214.83 27.9 211.43 29.2333 208.897 31.9C206.43 34.5 205.197 38.2667 205.197 43.2V74H193.897V43.2C193.897 38.2667 192.664 34.5 190.197 31.9C187.73 29.2333 184.364 27.9 180.097 27.9C175.83 27.9 172.43 29.2333 169.897 31.9C167.43 34.5 166.197 38.2667 166.197 43.2V74H154.797V18.9H166.197V25.2C168.064 22.9333 170.43 21.1667 173.297 19.9C176.164 18.6333 179.23 18 182.497 18C186.897 18 190.83 18.9333 194.297 20.8C197.764 22.6667 200.43 25.3667 202.297 28.9C203.964 25.5667 206.564 22.9333 210.097 21C213.63 19 217.43 18 221.497 18Z" />
      <circle style={{ fill: '#00CCCC' }} cx="268.5" cy="74.5" r="15.5" />
    </svg>
  );
};

export default Logo;

import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stepper, Step, StepLabel, Typography, CircularProgress } from '@mui/material';
import { CenteredLayout, CustomButtonContainer } from '@blumtechgroup/blum-react-core-components';
import { ValidatorsUtils } from '@blumtechgroup/blum-ui-utils';
import { AuthContext, EnumUserRole } from '../../../components/AuthProvider';
import { Logo } from '../../../components/Logos';
import { Title, Content, Modal, LogoContainer, Form, InputField } from './styled';
import { updateAdminUserMutation } from './mutations';
import { useMutation } from '@apollo/client';

interface CustomInitialSignInError {
  first_name?: string;
  last_name?: string;
  newPassword?: string;
  confirmPassword?: string;
  changePassword?: string;
}

const InitialSignIn = (): React.ReactElement => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }
  const { loggedInUser, userRole, user, signOut, signIn, completeNewPassword } = authContext;

  const [updateAdminUser] = useMutation(updateAdminUserMutation);

  const givenName = loggedInUser && loggedInUser.challengeParam ? loggedInUser.challengeParam.userAttributes.given_name || '' : '';
  const familyName = loggedInUser && loggedInUser.challengeParam ? loggedInUser.challengeParam.userAttributes.family_name || '' : '';

  const [first_name, setFirstName] = useState<string>(givenName);
  const [last_name, setLastName] = useState<string>(familyName);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState<string>('');
  const [errors, setErrors] = useState<CustomInitialSignInError>({});
  const [changingPassword, setChangingPassword] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [steps] = useState(['Name', 'Password', 'Details']);

  const handleNext = useCallback(() => {
    if (activeStep === 1 && !userRole) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep, userRole]);

  const handleGoToSignIn = useCallback(() => {
    navigate(`/sign-in?e=${loggedInUser?.username}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  const validate = useCallback(() => {
    let error = false;
    setErrors({});
    if (activeStep === 0) {
      if (!first_name) {
        setErrors((e) => ({ ...e, first_name: 'Please provide a first name' }));
        error = true;
      }
      if (!last_name) {
        setErrors((e) => ({ ...e, last_name: 'Please provide a last name' }));
        error = true;
      }
    }
    if (activeStep === 1) {
      if (!newPassword) {
        setErrors((e) => ({ ...e, newPassword: 'Please provide a password' }));
        error = true;
      } else if (!ValidatorsUtils.validatePassword(newPassword)) {
        setErrors((e) => ({
          ...e,
          newPassword: 'Password does not conform to the passowrd criteria',
        }));
        error = true;
      }
      if (!confirmPassword) {
        setErrors((e) => ({
          ...e,
          confirmPassword: 'Please provide a password',
        }));
        error = true;
      } else if (confirmPassword !== newPassword) {
        setErrors((e) => ({
          ...e,
          newPassword: 'Passwords do not match',
          confirmPassword: 'Passwords do not match',
        }));
        error = true;
      } else if (!ValidatorsUtils.validatePassword(confirmPassword)) {
        setErrors((e) => ({
          ...e,
          confirmPassword: 'Password does not conform to the passowrd criteria',
        }));
        error = true;
      }
    }
    return !error;
  }, [activeStep, confirmPassword, first_name, last_name, newPassword]);

  const handleContinue = useCallback(async () => {
    if (validate()) {
      if (activeStep === 1) {
        setChangingPassword(true);
        const attributes = {
          given_name: first_name,
          family_name: last_name,
        };
        try {
          const email = loggedInUser?.username || '';
          await completeNewPassword(newPassword, attributes);
          await signOut();
          await signIn(email, newPassword);
          setChangingPassword(false);
        } catch (err: any) {
          setErrors((e) => ({ ...e, changePassword: err.message }));
        }
      }
      if (activeStep === 2) {
        const updateUserVariables = {
          pk_columns: {
            id: user?.id,
          },
          set: {
            first_name,
            last_name,
          },
        };
        if (userRole === EnumUserRole.ADMIN) {
          await updateAdminUser({ variables: updateUserVariables });
        }
        await signOut();
        handleGoToSignIn();
      }
      if (activeStep < steps.length - 1) {
        return handleNext();
      }
    }
  }, [
    validate,
    activeStep,
    steps.length,
    first_name,
    last_name,
    loggedInUser?.username,
    completeNewPassword,
    newPassword,
    signOut,
    signIn,
    user?.id,
    userRole,
    handleGoToSignIn,
    updateAdminUser,
    handleNext,
  ]);

  useEffect(() => {
    let mounted = true;
    if (mounted && userRole && !userType) {
      setUserType(userRole);
      handleContinue();
    }
    return () => {
      mounted = false;
    };
  }, [userRole, userType, handleContinue]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Form>
            <InputField
              data-qa="first_name-textfield"
              required
              label="First name"
              variant="outlined"
              name="first_name"
              error={errors.first_name !== undefined}
              helperText={errors.first_name}
              value={first_name}
              autoComplete="off"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value || '')}
            />
            <InputField
              data-qa="last_name-textField"
              required
              label="Last name"
              variant="outlined"
              name="last_name"
              error={errors.last_name !== undefined}
              helperText={errors.last_name}
              value={last_name}
              autoComplete="off"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value || '')}
            />
          </Form>
        );
      case 1:
        return (
          <Form>
            <Typography sx={{ marginBottom: (theme) => theme.spacing(2) }}>
              Passwords must be at least 8 characters long and contain at least 1 lower case letter, 1 upper case letter, and a number
            </Typography>
            <InputField
              required
              label="New password"
              name="password"
              type="password"
              variant="outlined"
              error={errors.newPassword !== undefined}
              helperText={errors.newPassword}
              autoComplete="off"
              value={newPassword}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value || '')}
            />
            <InputField
              required
              label="Confirm new password"
              name="passwordConfirm"
              variant="outlined"
              type="password"
              error={errors.confirmPassword !== undefined}
              autoComplete="off"
              helperText={errors.confirmPassword}
              value={confirmPassword}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value || '')}
            />
          </Form>
        );
      case 2:
        return <Typography>By clicking finish you accept Terms and Conditions</Typography>;
      default:
        return 'Unknown step';
    }
  };

  const getContent = () => (
    <>
      <Stepper sx={{ padding: 0, marginBottom: (theme) => theme.spacing(4) }} activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
      {errors.changePassword && <Typography>{errors.changePassword}</Typography>}
      <CustomButtonContainer center>
        {!changingPassword && (
          <Button data-qa="continue-finish-button" variant="contained" color="primary" onClick={handleContinue}>
            {activeStep < steps.length - 1 ? 'CONTINUE' : 'FINISH'}
          </Button>
        )}
        {changingPassword && <CircularProgress />}
      </CustomButtonContainer>
    </>
  );

  return (
    <CenteredLayout>
      <Modal>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Title variant="h4">Update user profile</Title>
        <Content>{getContent()}</Content>
      </Modal>
    </CenteredLayout>
  );
};

export default InitialSignIn;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { CreateClinicianUsersComponent } from '../../../../components/Global';

const CreateClinicianUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const handleFinish = (userId: string) => {
    navigate(`/loc/clinicians/${userId}`);
  };

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Home', link: '/loc/home' }, { label: 'Clinicians', link: `/loc/clinicians` }, { label: 'Create' }]}>
      <CreateClinicianUsersComponent handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default CreateClinicianUsers;

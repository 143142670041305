import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { selectLocationInitQuery, Location } from './queries';
import { ViewLocationComponent } from '../../../components/Global';

const Home = (): React.ReactElement => {
  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
    users_location_admins_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    users_clinicians_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    tbsa_results_aggregate: {
      aggregate: {
        count: 0,
      },
    },
  });

  const { data: locationInitData } = useQuery(selectLocationInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationInitData && locationInitData.locations?.length) {
      setLocation(locationInitData.locations[0]);
    }
  }, [locationInitData]);

  const actions = [
    {
      title: 'Users',
      counter: location.users_location_admins_aggregate.aggregate.count,
      createLink: `/loc/users/create`,
      viewLink: `/loc/users`,
    },
    {
      title: 'Clinicians',
      counter: location.users_clinicians_aggregate.aggregate.count,
      createLink: `/loc/clinicians/create`,
      viewLink: `/loc/clinicians`,
    },
    {
      title: 'TBSA results',
      counter: location.tbsa_results_aggregate.aggregate.count,
      viewLink: `/loc/tbsa`,
    },
  ];

  return (
    <DashboardLayout title="Location Dashboard">
      <ViewLocationComponent actions={actions} />
    </DashboardLayout>
  );
};

export default Home;

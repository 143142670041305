import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectOrganisationUserInitQuery = (userId: string) => ({
  users_organisation_admin_by_pk: params(
    { id: rawString(userId) },
    {
      first_name: types.string,
      last_name: types.string,
      email: types.string,
      active: types.boolean,
    }
  ),
});

type X = Types.Unpacked<typeof _selectOrganisationUserInitQuery>;
export type OrganisationUser = X['users_organisation_admin_by_pk'];

export const selectOrganisationUserInitQuery = (userId: string) =>
  gql`
    ${query(_selectOrganisationUserInitQuery(userId))}
  `;

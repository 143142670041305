import React, { useCallback, useState } from 'react';
import { CustomStepper } from '@blumtechgroup/blum-react-core-components';
import { Types } from '@blumtechgroup/blum-ui-utils';
import { Organisation, validationErrors, ValidationErrors, OrganisationReducerAction, LicenseType } from '../..';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';
import StepLicenses from '../StepLicenses';

interface Props {
  completeLabel: string;
  organisation: Organisation;
  licenseTypes: LicenseType[];
  dispatch: React.Dispatch<{ type: OrganisationReducerAction; value: any }>;
  handleSave: () => void;
}

const OrganisationSteps = ({ completeLabel, organisation, licenseTypes, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: Types.UnknownObject = {};
      if (_step === 0) {
        errorItems.name = !organisation.name ? validationErrors.name : false;
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [organisation, errors]
  );

  return (
    <CustomStepper
      step={step}
      isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
      completeLabel={completeLabel}
      setStep={setStep}
      validate={validate}
      handleComplete={handleSave}
      gridSize={8}
      stepperSteps={[
        {
          label: 'Details',
          component: <StepDetails organisation={organisation} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
        },
        {
          label: 'Licenses',
          component: <StepLicenses organisation={organisation} licenseTypes={licenseTypes} dispatch={dispatch} />,
        },
        {
          label: 'Summary',
          component: <StepReview organisation={organisation} licenseTypes={licenseTypes} setStep={setStep} />,
        },
      ]}
    />
  );
};

export default OrganisationSteps;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { DashboardLayout, ReadOnlyBlock } from '@blumtechgroup/blum-react-core-components';
import { useQuery } from '@apollo/client';
import { selectAdminUserInitQuery, AdminUser } from './queries';
import { StringUtils, Types } from '@blumtechgroup/blum-ui-utils';

const AdminViewUser = (): React.ReactElement => {
  const navigate = useNavigate();
  const { admin_user_id } = useParams();
  const [user, setUser] = useState<AdminUser | Types.UnknownObject>({});
  const { data: adminUserInitQuery } = useQuery(selectAdminUserInitQuery(admin_user_id ?? ''), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (adminUserInitQuery) {
      const usr = adminUserInitQuery.users_admin_by_pk;
      setUser(usr);
    }
  }, [adminUserInitQuery]);

  const handleEdit = () => {
    navigate(`/admin/users/${admin_user_id}/edit`);
  };

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Admin', link: '/admin/home' }, { label: 'Users', link: '/admin/users' }, { label: `${user.full_name}` }]}>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <ReadOnlyBlock
            items={[
              { label: 'Name', value: user.full_name },
              { label: 'Email', value: user.email },
              { label: 'Active', value: StringUtils.booleanToYesNo(user.active) },
            ]}
            handleEdit={handleEdit}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default AdminViewUser;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useQuery } from '@apollo/client';
import { Organisation, organisationUserInitQuery } from './queries';
import { ListOrganisationUsersComponent } from '../../../../components/Global';

const ListOrganisationUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const [organisation, setOrganisation] = useState<Organisation>({
    id: '',
  });

  const { data: organisationUserInitData } = useQuery(organisationUserInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (organisationUserInitData && organisationUserInitData.organisations.length) {
      const { organisations } = organisationUserInitData;
      setOrganisation(organisations[0]);
    }
  }, [organisationUserInitData]);

  const handleView = (userId: string) => {
    navigate(`/org/users/${userId}`);
  };

  const handleEdit = (userId: string) => {
    navigate(`/org/users/${userId}/edit`);
  };

  const handleCreate = () => {
    navigate(`/org/users/create`);
  };

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Home', link: '/org/home' }, { label: 'Users' }]}>
      <ListOrganisationUsersComponent organisationId={organisation.id} handleCreate={handleCreate} handleEdit={handleEdit} handleView={handleView} />
    </DashboardLayout>
  );
};

export default ListOrganisationUsers;

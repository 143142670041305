import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectOrganisationLicensesInitQuery = (licenseTypeId: string) => ({
  license_types_by_pk: params({ id: rawString(licenseTypeId) }, { name: types.string }),
  locations: [
    {
      id: types.string,
      name: types.string,
      license_type_assignments: params(
        {
          where: {
            license_type_id: { _eq: rawString(licenseTypeId) },
          },
        },
        [
          {
            count: types.number,
          },
        ]
      ),
    },
  ],
  organisation_license_type_assignments: params(
    {
      where: {
        license_type_id: { _eq: rawString(licenseTypeId) },
      },
    },
    [
      {
        count: types.number,
      },
    ]
  ),
});

type X = Types.Unpacked<typeof _selectOrganisationLicensesInitQuery>;
export type LicenseType = X['license_types_by_pk'];
export type Location = X['locations'][0];
export type LicenseTypeAssignment = X['organisation_license_type_assignments'][0];

export const selectOrganisationLicensesInitQuery = (licenseTypeId: string) =>
  gql`
    ${query(_selectOrganisationLicensesInitQuery(licenseTypeId))}
  `;

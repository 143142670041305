import React, { useState } from 'react';
import { Button, Chip, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import { LicenseTypeAssignment, Location, LocationReducerAction } from '../..';
import DeleteIcon from '@mui/icons-material/Delete';
import { LicenseType } from '../../../Create/queries';

interface Props {
  location: Location;
  licenseTypes: LicenseType[];
  assignedLicenses: LicenseTypeAssignment[];
  dispatch: React.Dispatch<{ type: LocationReducerAction; value: any }>;
}

interface PageState {
  license: string;
  licenseError: boolean;
  licenseErrorMessage: string;
  count: number;
  countError: boolean;
  countErrorMessage: string;
}

const StepLicenses = ({ location, licenseTypes, assignedLicenses, dispatch }: Props): React.ReactElement => {
  const [licenseState, setLicenseState] = useState<PageState>({
    license: '',
    licenseError: false,
    licenseErrorMessage: '',
    count: 0,
    countError: false,
    countErrorMessage: '',
  });

  const handleAddLicense = () => {
    const licenseError = !licenseState.license;
    const countError = !licenseState.count || licenseState.count < 1;

    const numberInUse = assignedLicenses
      .filter((i) => i.location_id !== location.id)
      .filter((i) => i.license_type_id === licenseState.license)
      .map((i) => i.count)
      .reduce((t, i) => t + i, 0);
    const available = (licenseTypes.find((item) => item.license_type_id === licenseState.license)?.count ?? 0) - numberInUse;
    const countQuotaError = licenseState.count > available;

    setLicenseState((prev) => ({
      ...prev,
      licenseError: licenseError,
      licenseErrorMessage: licenseError ? 'Please select a license type' : '',
      countError: countError || countError,
      countErrorMessage: countError ? 'Count is required' : countQuotaError ? 'Not enough quota' : '',
    }));

    if (!(licenseError || countError || countQuotaError)) {
      dispatch({
        type: LocationReducerAction.ADD_LICENSE,
        value: {
          license_type_id: licenseState.license,
          count: licenseState.count,
        },
      });

      setLicenseState((prev) => ({ ...prev, license: '', count: 0 }));
    }
  };

  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12}>
            <Divider textAlign="left">Available licenses:</Divider>
          </Grid>
          {licenseTypes.map((item) => {
            const numberInUse = assignedLicenses
              .filter((i) => i.location_id !== location.id)
              .filter((i) => i.license_type_id === item.license_type_id)
              .map((i) => i.count)
              .reduce((t, i) => t + i, 0);

            return (
              <Grid item xs={4}>
                <Chip
                  label={
                    <Typography variant="body1" component="span">
                      {item.license_type.name} - {item.count - numberInUse}
                    </Typography>
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FormControl error={licenseState.licenseError} sx={{ width: '100%' }}>
              <InputLabel id="select-license-label">License Type</InputLabel>
              <Select
                labelId="select-license-label"
                id="select-license"
                value={licenseState.license}
                label="License Type"
                onChange={(e) => setLicenseState((prev) => ({ ...prev, license: e.target.value }))}>
                {licenseTypes.map((item) => (
                  <MenuItem value={item.license_type_id}>{item.license_type.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText error={licenseState.licenseError}>{licenseState.licenseErrorMessage}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              label="Count"
              variant="outlined"
              type="number"
              sx={{ width: '100%' }}
              error={licenseState.countError}
              helperText={licenseState.countErrorMessage}
              value={licenseState.count}
              onChange={(e) => setLicenseState((prev) => ({ ...prev, count: +e.target.value }))}
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth={true} variant="outlined" color="primary" onClick={handleAddLicense}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        {location.license_type_assignments.length ? (
          <List>
            {location.license_type_assignments.map((item) => (
              <ListItem key={item.license_type_id} disablePadding>
                <ListItem sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Grid container justifyContent="space-between">
                    <Grid item>{licenseTypes.find((l) => l.license_type_id === item.license_type_id)?.license_type.name}</Grid>
                    <Grid item>{item.count}</Grid>
                  </Grid>
                </ListItem>
                <Tooltip title="Delete">
                  <IconButton onClick={(_) => dispatch({ type: LocationReducerAction.DELETE_LICENSE, value: item.license_type_id })}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="subtitle2">There are no licenses currently assigned</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default StepLicenses;

import React from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useNavigate } from 'react-router-dom';
import { ListLocationUsersComponent } from '../../../../components/Global';

const ListLocationUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const handleView = (userId: string) => {
    navigate(`/loc/users/${userId}`);
  };

  const handleEdit = (userId: string) => {
    navigate(`/loc/users/${userId}/edit`);
  };

  const handleCreate = () => {
    navigate(`/loc/users/create`);
  };

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Home', link: '/loc/home' }, { label: 'Users' }]}>
      <ListLocationUsersComponent handleView={handleView} handleEdit={handleEdit} handleCreate={handleCreate} />
    </DashboardLayout>
  );
};

export default ListLocationUsers;

import { StringUtils } from '@blumtechgroup/blum-ui-utils';

export interface LicenseType {
  id: string;
  name: string;
}

export interface LicenseTypeAssignment {
  organisation_id: string;
  license_type_id: string;
  count: number;
}

export interface Organisation {
  name: string;
  active: boolean;
  premium: boolean;
  license_type_assignments: LicenseTypeAssignment[];
}

export interface ValidationErrors {
  name: string | boolean;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide organisation name',
};

export enum OrganisationReducerAction {
  NAME,
  ACTIVE,
  ADD_LICENSE,
  DELETE_LICENSE,
  INIT,
  PREMIUM,
}

export const organisationReducer = (state: Organisation, action: { type: OrganisationReducerAction; value: any }): Organisation => {
  switch (action.type) {
    case OrganisationReducerAction.NAME:
      return { ...state, name: StringUtils.capitaliseFirst(action.value) };
    case OrganisationReducerAction.ACTIVE:
      return { ...state, active: action.value };
    case OrganisationReducerAction.ADD_LICENSE:
      if (state.license_type_assignments.some((i) => i.license_type_id === action.value.license_type_id)) {
        return {
          ...state,
          license_type_assignments: state.license_type_assignments.map((item) => {
            if (item.license_type_id === action.value.license_type_id) {
              return {
                ...item,
                count: action.value.count,
              };
            }

            return item;
          }),
        };
      }

      return {
        ...state,
        license_type_assignments: [...state.license_type_assignments, action.value],
      };
    case OrganisationReducerAction.DELETE_LICENSE:
      return { ...state, license_type_assignments: state.license_type_assignments.filter((item) => item.license_type_id !== action.value) };
    case OrganisationReducerAction.INIT:
      const { name, active, premium, license_type_assignments } = action.value;
      const obj = {
        name,
        active,
        premium,
        license_type_assignments,
      };
      return { ...(obj as Organisation) };
    case OrganisationReducerAction.PREMIUM:
      return { ...state, premium: action.value };
    default:
      throw new Error();
  }
};

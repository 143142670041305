import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectLicenseTypesInitQuery = () => ({
  organisation_license_type_assignments: [
    {
      license_type: {
        id: types.string,
        name: types.string,
      },
      count: types.number,
    },
  ],
});

type X = Types.Unpacked<typeof _selectLicenseTypesInitQuery>;
export type LicenseType = X['organisation_license_type_assignments'][0];

export const selectLicenseTypesInitQuery = () =>
  gql`
    ${query(_selectLicenseTypesInitQuery())}
  `;

import { StringUtils } from '@blumtechgroup/blum-ui-utils';

export interface LicenseTypeAssignment {
  license_type_id: string;
  location_id: string;
  count: number;
}

export interface Location {
  id?: string;
  name: string;
  active: boolean;
  license_type_assignments: LicenseTypeAssignment[];
}

export interface ValidationErrors {
  name: string | boolean;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a location name',
};

export enum LocationReducerAction {
  NAME,
  ACTIVE,
  ADD_LICENSE,
  DELETE_LICENSE,
  INIT,
}

export const locationReducer = (state: Location, action: { type: LocationReducerAction; value: any }): Location => {
  switch (action.type) {
    case LocationReducerAction.NAME:
      return { ...state, name: StringUtils.capitaliseFirst(action.value) };
    case LocationReducerAction.ACTIVE:
      return { ...state, active: action.value };
    case LocationReducerAction.ADD_LICENSE:
      if (state.license_type_assignments.some((item) => item.license_type_id === action.value.license_type_id)) {
        return {
          ...state,
          license_type_assignments: state.license_type_assignments.map((item) => {
            if (item.license_type_id === action.value.license_type_id) {
              return {
                ...item,
                count: action.value.count,
              };
            }

            return item;
          }),
        };
      }

      return {
        ...state,
        license_type_assignments: [...state.license_type_assignments, action.value],
      };
    case LocationReducerAction.DELETE_LICENSE:
      return {
        ...state,
        license_type_assignments: state.license_type_assignments.filter((item) => item.license_type_id !== action.value),
      };
    case LocationReducerAction.INIT:
      const { id, name, active, license_type_assignments } = action.value;
      const obj = {
        id,
        name,
        active,
        license_type_assignments,
      };

      return { ...(obj as Location) };
    default:
      throw new Error();
  }
};

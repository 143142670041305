import { gql } from '@apollo/client';

export const updateClinicianUserMutation = gql`
  mutation (
    $pk_columns: users_clinician_pk_columns_input!
    $set: users_clinician_set_input!
    $insert_licenses: [clinician_license_type_assignments_insert_input!]!
    $delete_licenses: [uuid]!
  ) {
    update_users_clinician_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
    insert_clinician_license_type_assignments(objects: $insert_licenses, on_conflict: { constraint: clinician_license_type_assignments_pkey, update_columns: [license_type_id] }) {
      returning {
        clinician_id
      }
    }
    delete_clinician_license_type_assignments(where: { clinician_id: { _in: $delete_licenses } }) {
      affected_rows
    }
  }
`;

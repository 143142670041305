import React from 'react';
import { CustomSortableTable, SortableTableFilter, SortableTableRow } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { User } from '../..';

interface Props {
  users: User[];
  tableTitle: string;
  activeFiltered: boolean;
  setActiveFiltered: (value: boolean) => void;
  handleCreate: () => void;
  handleEdit: (id: string) => void;
  handleView: (id: string) => void;
}

const TableViewComponent = ({ users, tableTitle, activeFiltered, handleCreate, handleView, handleEdit, setActiveFiltered }: Props): React.ReactElement => {
  const rows = users.map((item) => {
    return {
      key: item.id,
      actions: [
        {
          label: 'View',
          onClick: () => handleView(item.id),
        },
        {
          label: 'Edit',
          onClick: () => handleEdit(item.id),
        },
      ],
      columns: [
        {
          key: 'full_name',
          label: item.full_name,
        },
        {
          key: 'email',
          label: item.email,
        },
        {
          key: 'active',
          label: StringUtils.booleanToYesNo(item.active),
        },
      ],
    };
  });

  const activeFilter: SortableTableFilter = {
    key: 'activeFilter',
    label: 'Active',
    filter: (rows: SortableTableRow[]) =>
      rows.filter((r) => {
        const col = r.columns.find((c) => c.key === 'active');
        if (col) {
          return activeFiltered ? col.label === 'Yes' : col.label === 'No';
        }
        return true;
      }),
    component: (
      <ToggleButtonGroup size="medium" value={[activeFiltered]} color="primary" onChange={() => setActiveFiltered(!activeFiltered)}>
        <ToggleButton key="active" value={true}>
          Active
        </ToggleButton>

        <ToggleButton key="inactive" value={false}>
          Inactive
        </ToggleButton>
      </ToggleButtonGroup>
    ),
  };

  const filters = [activeFilter];

  const filterRows = (): SortableTableRow[] => {
    let filteredRows: SortableTableRow[] = rows;
    filters.forEach((f) => {
      filteredRows = f.filter(filteredRows);
    });
    return filteredRows;
  };

  return (
    <CustomSortableTable
      filters={filters}
      searchable={['full_name']}
      title={tableTitle}
      counter={'filtered'}
      actions={[
        {
          key: 'create',
          label: 'Create',
          onClick: handleCreate,
        },
      ]}
      headers={[
        {
          key: 'full_name',
          label: 'Full name',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'active',
          label: 'Active',
        },
      ]}
      rows={filterRows()}
    />
  );
};

export default TableViewComponent;

import { StringUtils } from '@blumtechgroup/blum-ui-utils';

export interface LicenceType {
  name: string;
}

export interface ValidationErrors {
  name: string | boolean;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide license type name',
};

export enum LicenseTypeReducerAction {
  NAME,
  INIT,
}

export const licenseTypeReducer = (state: LicenceType, action: { type: LicenseTypeReducerAction; value: any }): LicenceType => {
  switch (action.type) {
    case LicenseTypeReducerAction.NAME:
      return { ...state, name: StringUtils.capitaliseFirst(action.value) };
    case LicenseTypeReducerAction.INIT:
      const { name } = action.value;
      const obj = {
        name,
      };
      return { ...(obj as LicenceType) };
    default:
      throw new Error();
  }
};

import { gql } from '@apollo/client';

export const selectLocationUsersQuery = gql`
  query ($locationId: uuid!) {
    locations_by_pk(id: $locationId) {
      name
      organisation {
        name
      }
    }
  }
`;

export interface Location {
  locations_by_pk: {
    name: string;
    organisation: {
      name: string;
    };
  };
}

import { gql } from '@apollo/client';

export const updateOrganisationMutation = gql`
  mutation (
    $pk_columns: organisations_pk_columns_input!
    $set: organisations_set_input!
    $license_types: [organisation_license_type_assignments_insert_input!]!
    $delete_license_types: [uuid]!
    $organisation_id: uuid!
  ) {
    update_organisations_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
    insert_organisation_license_type_assignments(objects: $license_types, on_conflict: { constraint: organisation_license_type_assignments_pkey, update_columns: [count] }) {
      returning {
        organisation_id
        license_type_id
      }
    }
    delete_organisation_license_type_assignments(where: { _and: [{ license_type_id: { _in: $delete_license_types } }, { organisation_id: { _eq: $organisation_id } }] }) {
      affected_rows
    }
  }
`;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { CustomSortableTable, DashboardLayout, ReadOnlyBlock } from '@blumtechgroup/blum-react-core-components';
import { useQuery } from '@apollo/client';
import { selectLocationLicensesInitQuery, Location, LicenseType } from './queries';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';

type UrlParams = {
  license_type_id: string;
  organisation_id: string;
  location_id: string;
};

interface ViewLocationLicensesPageState {
  location: Location;
  licenseType: LicenseType;
}

const ViewLocationLicenses = (): React.ReactElement => {
  const navigate = useNavigate();

  const { license_type_id, organisation_id, location_id } = useParams<UrlParams>();

  const [pageState, setPageState] = useState<ViewLocationLicensesPageState>({
    location: {
      name: '',
      organisation: {
        name: '',
      },
      license_type_assignments: [],
      users_clinicians: [],
    },
    licenseType: {
      name: '',
    },
  });

  const { data: locationLicensesData } = useQuery(selectLocationLicensesInitQuery(location_id!, license_type_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationLicensesData) {
      setPageState({
        location: locationLicensesData.locations_by_pk,
        licenseType: locationLicensesData.license_types_by_pk,
      });
    }
  }, [locationLicensesData]);

  const handleEditClinician = (clinicianId: string) => {
    navigate(`/admin/organisations/${organisation_id}/locations/${location_id}/clinicians/${clinicianId}/edit`);
  };

  const handleEdit = () => {
    navigate(`/admin/license-types/${license_type_id}/edit`);
  };

  const rows = pageState.location.users_clinicians.map((item) => {
    return {
      key: item.id,
      actions: [
        {
          label: 'Edit',
          onClick: () => handleEditClinician(item.id),
        },
      ],
      columns: [
        {
          key: 'name',
          label: StringUtils.stringFromArray([item.first_name, item.last_name]),
        },
        {
          key: 'email',
          label: item.email,
        },
      ],
    };
  });

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'License Types', link: '/admin/license-types' },
        { label: pageState.licenseType.name, link: `/admin/license-types/${license_type_id}` },
        { label: pageState.location.organisation.name, link: `/admin/license-types/${license_type_id}/organisations/${organisation_id}` },
        { label: pageState.location.name },
      ]}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReadOnlyBlock
            gridSize={4}
            items={[
              {
                label: 'Name',
                components: [
                  <Typography variant="h5" component="span">
                    {pageState.licenseType.name}
                  </Typography>,
                ],
              },
              {
                label: 'Available licenses',
                value: pageState.location.license_type_assignments.length ? pageState.location.license_type_assignments[0].count.toString() : '0',
              },
            ]}
            handleEdit={handleEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSortableTable
            searchable={['name']}
            title={'Clinicians'}
            counter={'filtered'}
            headers={[
              {
                key: 'name',
                label: 'Name',
              },
              {
                key: 'email',
                label: 'Email',
              },
            ]}
            rows={rows}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ViewLocationLicenses;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useQuery } from '@apollo/client';
import { selectOrganisationUserInitQuery, OrganisationUser } from './queries';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { ViewOrganisationUsersComponent } from '../../../../../components/Global';

type UrlParams = {
  organisation_id: string;
  user_id: string;
};

const ViewOrganisationUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { organisation_id, user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<OrganisationUser>({
    first_name: '',
    last_name: '',
    organisation: {
      name: '',
    },
  });
  const { data: organisationUserInitData } = useQuery(selectOrganisationUserInitQuery(user_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (organisationUserInitData) {
      setUser(organisationUserInitData.users_organisation_admin_by_pk);
    }
  }, [organisationUserInitData]);

  const handleEdit = () => {
    navigate(`/admin/organisations/${organisation_id}/users/${user_id}/edit`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Organisations', link: '/admin/organisations' },
        { label: user.organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Users', link: `/admin/organisations/${organisation_id}/users` },
        { label: StringUtils.stringFromArray([user.first_name, user.last_name]) },
      ]}>
      <ViewOrganisationUsersComponent userId={user_id!} handleEdit={handleEdit} />
    </DashboardLayout>
  );
};

export default ViewOrganisationUsers;

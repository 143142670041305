import { gql } from '@apollo/client';

export const insertOrganisationMutation = gql`
  mutation ($object: organisations_insert_input!) {
    insert_organisations_one(object: $object) {
      id
    }
  }
`;

export interface InsertOrganisationResponse {
  insert_organisations_one: {
    id: string;
  };
}

import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectAdminUsersInitQuery = {
  users_admin: {
    id: types.string,
    full_name: types.string,
    email: types.string,
    active: types.boolean,
  },
};

type X = Types.Unpacked<typeof _selectAdminUsersInitQuery>;
export type AdminUser = X['users_admin'];

export const selectAdminUsersInitQuery = gql`
  ${query(_selectAdminUsersInitQuery)}
`;

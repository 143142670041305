import { types, query, params, rawString } from 'typed-graphqlify';
import { Types } from '@blumtechgroup/blum-ui-utils';
import { gql } from '@apollo/client';

const _createLocationInitQuery = (organisationId?: string) => ({
  organisations: params(
    {
      offset: 0,
      ...(organisationId
        ? {
            where: { id: { _eq: rawString(organisationId) } },
          }
        : {}),
    },
    [
      {
        name: types.string,
        license_type_assignments: [
          {
            license_type_id: types.string,
            count: types.number,
            license_type: {
              name: types.string,
            },
          },
        ],
        location_license_type_assignments: [
          {
            license_type_id: types.string,
            location_id: types.string,
            count: types.number,
          },
        ],
      },
    ]
  ),
});

type X = Types.Unpacked<typeof _createLocationInitQuery>;
export type Organisation = X['organisations'][0];
export type LicenseType = X['organisations'][0]['license_type_assignments'][0];

export const createLocationInitQuery = (organisationId?: string) =>
  gql`
    ${query(_createLocationInitQuery(organisationId))}
  `;

export const getAllocatedLicensesQuery = gql`
  query ($licenseTypeIds: [uuid]!) {
    location_license_type_assignments(where: { license_type_id: { _in: $licenseTypeIds } }) {
      license_type_id
      count
    }
  }
`;

export const getAllocatedLicensesForOrganisationQuery = gql`
  query ($licenseTypeIds: [uuid]!, $organisationId: uuid!) {
    location_license_type_assignments(where: { _and: [{ license_type_id: { _in: $licenseTypeIds } }, { organisation_id: { _eq: $organisationId } }] }) {
      license_type_id
      count
    }
  }
`;

export interface AllocatedLicensesVariables {
  licenseTypeIds: string[];
  organisationId?: string;
}

export interface AllocatedLicensesResponse {
  location_license_type_assignments: { license_type_id: string; count: number }[];
}

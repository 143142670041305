import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _organisationUserInitQuery = () => ({
  organisations: [
    {
      id: types.string,
    },
  ],
});

type X = Types.Unpacked<typeof _organisationUserInitQuery>;
export type Organisation = X['organisations'][0];

export const organisationUserInitQuery = () =>
  gql`
    ${query(_organisationUserInitQuery())}
  `;

import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './components/App';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://35222997ece14a93a628e882c0a5946d@o777343.ingest.sentry.io/4505391630254080',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: 'mersey-burns-core-app@1.0.0',
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

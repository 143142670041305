import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CustomDialog, DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { selectLocationInitQuery, Location } from './queries';
import { ViewLocationComponent } from '../../../../../components/Global';

type UrlParams = {
  organisation_id: string;
  location_id: string;
};

const ViewLocation = (): React.ReactElement => {
  const { organisation_id, location_id } = useParams<UrlParams>();
  const navigate = useNavigate();
  const routeState = useLocation();

  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState<boolean>(routeState?.state?.isApprove);

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
    users_location_admins_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    users_clinicians_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    tbsa_results_aggregate: {
      aggregate: {
        count: 0,
      },
    },
  });

  const { data: locationInitData } = useQuery(selectLocationInitQuery(location_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationInitData) {
      setLocation(locationInitData.locations_by_pk);
    }
  }, [locationInitData]);

  const actions = [
    {
      title: 'Users',
      counter: location.users_location_admins_aggregate.aggregate.count,
      createLink: `/admin/organisations/${organisation_id}/locations/${location_id}/users/create`,
      viewLink: `/admin/organisations/${organisation_id}/locations/${location_id}/users`,
    },
    {
      title: 'Clinicians',
      counter: location.users_clinicians_aggregate.aggregate.count,
      createLink: `/admin/organisations/${organisation_id}/locations/${location_id}/clinicians/create`,
      viewLink: `/admin/organisations/${organisation_id}/locations/${location_id}/clinicians`,
    },
    {
      title: 'TBSA results',
      counter: location.tbsa_results_aggregate.aggregate.count,
      viewLink: `/admin/organisations/${organisation_id}/locations/${location_id}/tbsa`,
    },
  ];

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Organisations', link: '/admin/organisations' },
        { label: location.organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Hospitals', link: `/admin/organisations/${organisation_id}/locations` },
        { label: location.name },
      ]}>
      <ViewLocationComponent actions={actions} />
      <CustomDialog
        title="Complete approval"
        message="Would you like to go back to requested account page and finish the approval process?"
        open={isApproveDialogOpen}
        handleClose={() => setIsApproveDialogOpen(false)}
        actions={[
          { label: 'Cancel', onClick: () => setIsApproveDialogOpen(false) },
          {
            label: 'Complete approval',
            onClick: () =>
              navigate(`/admin/requested-users/${routeState?.state?.email}/approve`, {
                state: routeState?.state ? { ...routeState.state, locationName: location.name, organisationName: location.organisation.name } : undefined,
              }),
          },
        ]}
      />
    </DashboardLayout>
  );
};

export default ViewLocation;

import React from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import ListTbsaResultsComponent from '../../../../components/Global/Organisations/Locations/Tbsa/List';

const ListTbsaResults = (): React.ReactElement => {
  return (
    <DashboardLayout breadcrumbs={[{ label: 'Home', link: '/loc/home' }, { label: 'TBSA' }]}>
      <ListTbsaResultsComponent />
    </DashboardLayout>
  );
};

export default ListTbsaResults;

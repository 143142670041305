import React, { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardLayout, CustomBackdrop } from '@blumtechgroup/blum-react-core-components';
import { licenseTypeReducer } from '../Common';
import Steps from '../Common/components/Steps';
import { insertLicenseTypeMutation, InsertLicenseTypeResponse } from './mutations';
import { useMutation } from '@apollo/client';

const CreateLicenseTypes = (): React.ReactElement => {
  const navigate = useNavigate();
  const [licenseType, dispatchLicenseType] = useReducer(licenseTypeReducer, {
    name: '',
  });

  const [createLicenseType, { loading }] = useMutation<InsertLicenseTypeResponse>(insertLicenseTypeMutation);

  const handleSave = async () => {
    const createVariables = {
      object: licenseType,
    };

    const { data } = await createLicenseType({ variables: createVariables });
    if (data) {
      handleFinish(data.insert_license_types_one.id);
    }
  };

  const handleFinish = (licenseTypeId: string) => {
    navigate(`/admin/license-types/${licenseTypeId}`);
  };

  return (
    <>
      <DashboardLayout breadcrumbs={[{ label: 'Admin', link: '/admin/home' }, { label: 'License Types', link: '/admin/license-types' }, { label: 'Create' }]}>
        <Steps completeLabel="Create" licenseType={licenseType} dispatch={dispatchLicenseType} handleSave={handleSave} />
      </DashboardLayout>
      {loading && <CustomBackdrop label="Creating License Type" />}
    </>
  );
};

export default CreateLicenseTypes;

import React, { useCallback, useReducer, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';
import { Types } from '@blumtechgroup/blum-ui-utils';
import { Button, CircularProgress, Typography } from '@mui/material';
import { CenteredLayout, CustomButtonContainer } from '@blumtechgroup/blum-react-core-components';
import Logo from '../../../components/Logos/Logo';
import { passwordResetReducer, PasswordResetReducerAction, validation, validationErrors, ValidationErrors } from './Common';
import { Root, LogoContainer, Content, Info, Form, InputField } from './styled';

const ResetPassword = (): React.ReactElement => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const queryEmail = atob(query.get('e') || '');

  const [passwordReset, dispatch] = useReducer(passwordResetReducer, {
    email: queryEmail,
    password: '',
    passwordConfirm: '',
    verificationCode: '',
  });

  const [errors, setErrors] = useState<ValidationErrors>({
    email: false,
    validEmail: false,
    password: false,
    passwordConfirm: false,
    passwordMatch: false,
    verificationCode: false,
    verificationCodeLength: false,
    reset: false,
  });

  const [isReset, setIsReset] = useState(false);
  const [reseting, setReseting] = useState(false);

  const validate = useCallback(() => {
    const errorItems: Types.UnknownObject = {};
    errorItems.email = validation.email(passwordReset.email);
    errorItems.password = validation.password(passwordReset.password, passwordReset.passwordConfirm);
    errorItems.passwordConfirm = validation.passwordConfirm(passwordReset.passwordConfirm, passwordReset.password);
    errorItems.verificationCode = validation.verificationCode(passwordReset.verificationCode);
    setErrors({ ...errors, ...errorItems });
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [passwordReset, errors]);

  const handleSubmit = () => {
    if (!validate()) {
      handleContinue();
    }
  };

  const handleContinue = async () => {
    setReseting(true);
    try {
      await Auth.forgotPasswordSubmit(passwordReset.email, passwordReset.verificationCode, passwordReset.password);
      setIsReset(true);
      setTimeout(() => {
        navigate('/sign-in');
      }, 2500);
    } catch (err: any) {
      setReseting(false);
      validation.reset(err.message);
    }
  };

  const getContent = () => (
    <>
      <Form>
        <InputField
          data-qa="email-textField"
          required
          label="Email address"
          type="email"
          error={errors.email as boolean}
          helperText={errors.email}
          value={passwordReset.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PasswordResetReducerAction.EMAIL, value: event.target.value })}
        />
        <InputField
          data-qa="verification-code-textField"
          required
          label="Verification code"
          type="number"
          error={errors.verificationCode as boolean}
          helperText={errors.verificationCode}
          value={passwordReset.verificationCode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PasswordResetReducerAction.VERIFICATION_CODE, value: event.target.value })}
        />
        <InputField
          data-qa="new-password-textField"
          required
          label="New password"
          type="password"
          error={errors.password as boolean}
          helperText={errors.password}
          value={passwordReset.password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PasswordResetReducerAction.PASSWORD, value: event.target.value })}
        />
        <InputField
          data-qa="confirm-password-textField"
          required
          label="Confirm new password"
          type="password"
          error={errors.passwordConfirm as boolean}
          helperText={errors.passwordConfirm}
          value={passwordReset.passwordConfirm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PasswordResetReducerAction.PASSWORD_CONFIRM, value: event.target.value })}
        />
      </Form>
      {isReset && <Typography align="center">Password has been reset. Please login again</Typography>}
      <CustomButtonContainer center>
        {!reseting && (
          <Button data-qa="reset-password-button" variant="contained" color="primary" onClick={handleSubmit}>
            RESET PASSWORD
          </Button>
        )}
        {reseting && <CircularProgress />}
      </CustomButtonContainer>
    </>
  );

  return (
    <CenteredLayout>
      <Root>
        <LogoContainer>
          <Logo />
        </LogoContainer>

        <Info>
          <Typography align="center" variant="caption" component="div">
            Please check your email for an AccentPOS ID verification code
          </Typography>
          <Typography sx={{ marginTop: (theme) => theme.spacing(1) }} align="center" variant="caption" component="div">
            Passwords must be at least 8 characters long and contain at least 1 lower case letter, 1 upper case letter, and a number
          </Typography>
        </Info>

        <Content>{getContent()}</Content>
      </Root>
    </CenteredLayout>
  );
};

export default ResetPassword;

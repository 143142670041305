import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _editLocationUserInitQuery = (userId: string) => ({
  users_location_admin_by_pk: params(
    { id: rawString(userId) },
    {
      first_name: types.string,
      last_name: types.string,
      location: {
        name: types.string,
        organisation: {
          name: types.string,
        },
      },
    }
  ),
});

type X = Types.Unpacked<typeof _editLocationUserInitQuery>;
export type LocationUser = X['users_location_admin_by_pk'];

export const editLocationUserInitQuery = (userId: string) =>
  gql`
    ${query(_editLocationUserInitQuery(userId))}
  `;

import { gql } from '@apollo/client';

export const insertAdminUserMutation = gql`
  mutation ($object: users_admin_insert_input!) {
    insert_users_admin_one(object: $object) {
      id
    }
  }
`;

export interface InsertAdminUserResponse {
  insert_users_admin_one: {
    id: string;
  };
}

import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _createLocationUserInitQuery = (locationId: string) => ({
  locations_by_pk: params(
    { id: rawString(locationId) },
    {
      name: types.string,
      organisation: {
        name: types.string,
      },
    }
  ),
});

type X = Types.Unpacked<typeof _createLocationUserInitQuery>;
export type Location = X['locations_by_pk'];

export const createLocationUserInitQuery = (locationId: string) =>
  gql`
    ${query(_createLocationUserInitQuery(locationId))}
  `;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useQuery } from '@apollo/client';
import { selectLocationsInitQuery, Organisation } from './queries';
import { ListLocationsComponent } from '../../../../components/Global';

const ListLocations = (): React.ReactElement => {
  const navigate = useNavigate();

  const [pageState, setPageState] = useState<Organisation>({
    id: '',
    name: '',
  });
  const { data: locationsInitData } = useQuery(selectLocationsInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationsInitData && locationsInitData.organisations?.length) {
      setPageState(locationsInitData.organisations[0]);
    }
  }, [locationsInitData]);

  const handleView = (locationId: string) => {
    navigate(`/org/locations/${locationId}`);
  };

  const handleEdit = (locationId: string) => {
    navigate(`/org/locations/${locationId}/edit`);
  };

  const handleCreate = () => {
    navigate(`/org/locations/create`);
  };

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Home', link: '/org/home' }, { label: 'Hospitals' }]}>
      <ListLocationsComponent organisationId={pageState.id} handleCreate={handleCreate} handleEdit={handleEdit} handleView={handleView} />
    </DashboardLayout>
  );
};

export default ListLocations;

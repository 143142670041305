import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { createLocationUserInitQuery, Location } from './queries';
import { useQuery } from '@apollo/client';
import { CreateLocationUsersComponent } from '../../../../../components/Global';

type UrlParams = {
  location_id: string;
};

const CreateLocationUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
  });

  const { data: createLocationUserInitData } = useQuery(createLocationUserInitQuery(location_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (createLocationUserInitData) {
      setLocation(createLocationUserInitData.locations_by_pk);
    }
  }, [createLocationUserInitData]);

  const handleFinish = (userId: string) => {
    navigate(`/org/locations/${location_id}/users/${userId}`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Home', link: '/org/home' },
        { label: 'Hospitals', link: `/org/locations` },
        { label: location.name, link: `/org/locations/${location_id}` },
        { label: 'Users', link: `/org/locations/${location_id}/users` },
        { label: 'Create' },
      ]}>
      <CreateLocationUsersComponent locationId={location_id!} handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default CreateLocationUsers;

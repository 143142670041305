import React, { useState, useEffect } from 'react';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { Grid } from '@mui/material';
import { useQuery } from '@apollo/client';
import { selectLocationUsersInitQuery, Location } from './queries';
import UsersList from '../../../../../../components/Global/Users/List';

interface Props {
  locationId?: string;
  handleView: (userId: string) => void;
  handleEdit: (userId: string) => void;
  handleCreate: () => void;
}

const ListLocationUsersComponent = ({ locationId, handleView, handleEdit, handleCreate }: Props): React.ReactElement => {
  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
    users_location_admins: [],
  });
  const { data: locationInitData } = useQuery(selectLocationUsersInitQuery(locationId), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationInitData && locationInitData.locations?.length) {
      setLocation(locationInitData.locations[0]);
    }
  }, [locationInitData]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <UsersList
          users={location.users_location_admins.map((item) => ({
            id: item.id,
            full_name: StringUtils.stringFromArray([item.first_name, item.last_name]),
            email: item.email,
            active: item.active,
          }))}
          userType="location"
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          handleView={handleView}
        />
      </Grid>
    </Grid>
  );
};

export default ListLocationUsersComponent;

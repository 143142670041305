import { types, query, params, rawString } from 'typed-graphqlify';
import { Types } from '@blumtechgroup/blum-ui-utils';
import { gql } from '@apollo/client';

const _selectClinicianUserInitQuery = (clinicianId: string) => ({
  users_clinician_by_pk: params(
    { id: rawString(clinicianId) },
    {
      first_name: types.string,
      last_name: types.string,
    }
  ),
});

type X = Types.Unpacked<typeof _selectClinicianUserInitQuery>;
export type ClinicianUser = X['users_clinician_by_pk'];

export const selectClinicianUserInitQuery = (clinicianId: string) =>
  gql`
    ${query(_selectClinicianUserInitQuery(clinicianId))}
  `;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { CustomBackdrop, CustomDialog, DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { requestedUserQuery, RequestedUser, Organisation } from './queries';
import { CreateClinicianUsersComponent } from '../../../../components/Global';
import { Autocomplete, Box, Button, FormControl, Paper, TextField, Typography } from '@mui/material';
import { updateRequestedUserMutation } from './mutations';

type Option = { label: string; id: string };

const ApproveRequestedUser = (): React.ReactElement => {
  const navigate = useNavigate();
  const routeState = useLocation();
  const params = useParams<{ email: string }>();

  const [user, setUser] = useState<RequestedUser>({
    email: '',
    is_approved: false,
    first_name: '',
    last_name: '',
    organisation_name: '',
    location_name: '',
  });

  const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation>({
    id: '',
    name: '',
    locations: [],
  });

  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { data: requestedUserInitQuery } = useQuery(requestedUserQuery(params.email as string), { fetchPolicy: 'no-cache' });
  const [approveRequest, { loading }] = useMutation(updateRequestedUserMutation);

  useEffect(() => {
    if (requestedUserInitQuery) {
      const { requested_users_by_pk } = requestedUserInitQuery;
      setUser(requested_users_by_pk);
    }
  }, [requestedUserInitQuery]);

  useEffect(() => {
    const orgName = routeState?.state?.organisationName ? routeState.state.organisationName : user.organisation_name;
    const locName = routeState?.state?.locationName ? routeState.state.locationName : user.location_name;

    if (orgName && locName && requestedUserInitQuery?.organisations?.length) {
      const org: Organisation = requestedUserInitQuery.organisations.find((o: Organisation) => o.name.toLocaleLowerCase().includes(orgName.toLocaleLowerCase()));
      if (org) {
        setSelectedOrganisation(org);
        const loc = org.locations.find((l) => l.name.toLocaleLowerCase().includes(locName.toLocaleLowerCase()));
        if (loc) {
          setSelectedLocation(loc.id);
        }
      }
    }
  }, [user, requestedUserInitQuery?.organisations, setSelectedLocation, setSelectedOrganisation, routeState?.state]);

  const handleFinish = async (id: string) => {
    const variables = {
      pk_columns: { email: user.email },
      set: {
        is_approved: true,
      },
    };

    await approveRequest({ variables });
    navigate(`/admin/organisations/${selectedOrganisation.id}/locations/${selectedLocation}/clinicians`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Requested users', link: '/admin/requested/users' },
        { label: `Approve ${user.first_name} ${user.last_name}` },
      ]}>
      {!!user.email && !!selectedLocation && (
        <CreateClinicianUsersComponent locationId={selectedLocation} organisationId={selectedOrganisation.id} approvedUser={user} handleFinish={handleFinish} />
      )}
      <CustomDialog
        actions={[{ label: 'Go back', onClick: () => navigate('/admin/requested-users') }, ...(selectedLocation ? [{ label: 'Confirm', onClick: () => setIsOpen(false) }] : [])]}
        open={requestedUserInitQuery?.organisations?.length && isOpen}
        title="Confirm the hospital name"
        handleClose={() => {}}
        component={
          <>
            {!!requestedUserInitQuery?.organisations?.length && (
              <>
                <Box mb={2}>
                  <Typography>Confirm that organisation & hospital names are valid</Typography>
                </Box>
                <Box mb={4}>
                  <Typography variant="body2">
                    Requested organisation name: <b>{user.organisation_name}</b>
                  </Typography>
                  <Typography variant="body2">
                    Requested hospital name: <b>{user.location_name}</b>
                  </Typography>
                </Box>

                <Box mb={2} gap={3} flexDirection={'column'} display={'flex'}>
                  <FormControl>
                    <Autocomplete
                      id="select-Organisation"
                      value={{ label: selectedOrganisation.name, id: selectedOrganisation.id }}
                      getOptionLabel={(option) => option.label}
                      options={
                        requestedUserInitQuery.organisations
                          .sort((a: Organisation, b: Organisation) => a.name.localeCompare(b.name))
                          .map((o: Organisation) => ({ label: o.name, id: o.id })) as Option[]
                      }
                      renderInput={(params) => <TextField {...params} label={'Organisation'}></TextField>}
                      PaperComponent={(props) => <Paper sx={{ boxShadow: 2 }} {...props}></Paper>}
                      onChange={(_, value) => value?.label && setSelectedOrganisation(requestedUserInitQuery.organisations.find((o: Organisation) => o.id === value?.id))}
                    />
                  </FormControl>
                  <FormControl>
                    <Autocomplete
                      id="select-Location"
                      value={{ label: selectedOrganisation.locations.find((l) => l.id === selectedLocation)?.name, id: selectedLocation }}
                      getOptionLabel={(option) => option.label ?? ''}
                      options={
                        selectedOrganisation.locations
                          .sort((a: { name: string; id: string }, b: { name: string; id: string }) => a.name.localeCompare(b.name))
                          .map((o: { name: string; id: string }) => ({ label: o.name, id: o.id })) as Option[]
                      }
                      renderInput={(params) => <TextField {...params} label={'Hospital'}></TextField>}
                      PaperComponent={(props) => <Paper sx={{ boxShadow: 2 }} {...props}></Paper>}
                      onChange={(_, value) => value?.label && setSelectedLocation(value.id)}
                    />
                  </FormControl>
                </Box>
                <Box>
                  If the organisation or hospital does not exist, create them below:
                  <ul>
                    <li>
                      Click
                      <Button
                        variant="text"
                        onClick={() =>
                          navigate('/admin/organisations/create', {
                            state: { organisationName: user.organisation_name, locationName: user.location_name, isApprove: true, email: user.email },
                          })
                        }>
                        here
                      </Button>
                      to create a new organisation
                    </li>
                    {!!selectedOrganisation.id && (
                      <li>
                        Click
                        <Button
                          variant="text"
                          onClick={() =>
                            navigate(`/admin/organisations/${selectedOrganisation.id}/locations/create`, {
                              state: { locationName: user.location_name, isApprove: true, email: user.email },
                            })
                          }>
                          here
                        </Button>
                        to create a new location within the <b>{selectedOrganisation.name}</b>
                      </li>
                    )}
                  </ul>
                </Box>
              </>
            )}
          </>
        }
      />
      {loading && <CustomBackdrop label="Loading..." />}
    </DashboardLayout>
  );
};

export default ApproveRequestedUser;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Grid, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { CustomBackdrop, CustomDialog, CustomSortableTable, DashboardLayout, SortableTableFilter, SortableTableRow } from '@blumtechgroup/blum-react-core-components';
import { requestedUsersQuery, RequestedUsersPageState } from './queries';
import { CheckCircle as ApproveIcon, Cancel as RejectIcon, AccessTimeFilled as PendingIcon } from '@mui/icons-material';
import { BaseGrid } from './styled';
import { updateRequestedUserMutation } from './mutations';

const ListRequestedUsers = (): React.ReactElement => {
  const approved = 'Approved',
    rejected = 'Rejected',
    pending = 'Pending';

  const navigate = useNavigate();
  const [users, setUsers] = useState<RequestedUsersPageState[]>([]);
  const [approvedFilter, setApprovedFilter] = useState<string | null>(pending);
  const [approvedUser, setApprovedUser] = useState<RequestedUsersPageState | null>(null);

  const { data: requestedUserInitQuery, refetch } = useQuery(requestedUsersQuery(), { fetchPolicy: 'no-cache' });
  const [rejectRequest, { loading }] = useMutation(updateRequestedUserMutation);

  useEffect(() => {
    if (requestedUserInitQuery) {
      const { requested_users } = requestedUserInitQuery;
      setUsers(requested_users);
    }
  }, [requestedUserInitQuery]);

  const handleApprove = (email: string) => {
    navigate(`/admin/requested-users/${email}/approve`);
  };

  const handleReject = async (email: string) => {
    const variables = {
      pk_columns: {
        email,
      },
      set: {
        is_approved: false,
      },
    };

    await rejectRequest({ variables });
    await refetch();
  };

  const rows = users.map((item) => {
    return {
      key: item.email,
      actions: [
        ...(item.is_approved
          ? []
          : [
              {
                label: 'Approve',
                onClick: () => setApprovedUser(item),
              },
            ]),
        ...(item.is_approved === null
          ? [
              {
                label: 'Reject',
                destructive: true,
                onClick: () => handleReject(item.email),
              },
            ]
          : []),
      ],
      columns: [
        {
          key: 'full_name',
          label: `${item.first_name} ${item.last_name}`,
        },
        {
          key: 'email',
          label: item.email,
        },
        {
          key: 'organisation',
          label: item.organisation_name,
        },
        {
          key: 'location',
          label: item.location_name,
        },
        {
          key: 'status',
          label: item.is_approved ? approved : item.is_approved === false ? rejected : pending,
          component: item.is_approved ? (
            <Tooltip title={approved}>
              <ApproveIcon color="success" />
            </Tooltip>
          ) : item.is_approved === false ? (
            <Tooltip title={rejected}>
              <RejectIcon color="error" />
            </Tooltip>
          ) : (
            <Tooltip title={pending}>
              <PendingIcon color="secondary" />
            </Tooltip>
          ),
        },
      ],
    };
  });

  const activeFilter: SortableTableFilter = {
    key: 'activeFilter',
    label: 'Status',
    filter: (rows: SortableTableRow[]) =>
      rows.filter((r) => {
        if (approvedFilter !== null) {
          const col = r.columns.find((c) => c.key === 'status');
          return approvedFilter && approvedFilter === approved ? col?.label === approved : approvedFilter === rejected ? col?.label === rejected : col?.label === pending;
        }
        return true;
      }),
    component: (
      <ToggleButtonGroup size="medium" exclusive value={approvedFilter} color="primary" onChange={(e, value) => setApprovedFilter(value)}>
        <ToggleButton key={approved} value={approved}>
          {approved}
        </ToggleButton>
        <ToggleButton key={pending} value={pending}>
          {pending}
        </ToggleButton>
        <ToggleButton key={rejected} value={rejected}>
          {rejected}
        </ToggleButton>
      </ToggleButtonGroup>
    ),
  };

  const filters = [activeFilter];

  const filterRows = (): SortableTableRow[] => {
    let filteredRows: SortableTableRow[] = rows;
    filters.forEach((f) => {
      filteredRows = f.filter(filteredRows);
    });
    return filteredRows;
  };

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Admin', link: '/admin/home' }, { label: 'Requested users' }]}>
      <Grid container spacing={4}>
        <BaseGrid item xs={12}>
          <CustomSortableTable
            filters={filters}
            searchable={['full_name']}
            title={'Requests'}
            counter={'filtered'}
            headers={[
              {
                key: 'full_name',
                label: 'Full name',
              },
              {
                key: 'email',
                label: 'Email',
              },
              {
                key: 'organisation',
                label: 'Organisation',
              },
              {
                key: 'location',
                label: 'Hospital',
              },
              {
                key: 'status',
                label: 'Status',
              },
            ]}
            rows={filterRows()}
          />
        </BaseGrid>
      </Grid>
      {loading && <CustomBackdrop label="Loading..." />}
      <CustomDialog
        open={approvedUser !== null}
        handleClose={() => setApprovedUser(null)}
        title="Confirm approve"
        message={`Are you sure you want to approve ${approvedUser?.first_name} ${approvedUser?.last_name}?`}
        actions={[
          {
            label: 'Cancel',
            onClick: () => setApprovedUser(null),
          },
          {
            label: 'Proceed',
            onClick: () => handleApprove(approvedUser?.email!),
          },
        ]}
      />
    </DashboardLayout>
  );
};

export default ListRequestedUsers;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { GridCard } from '@blumtechgroup/blum-react-core-components';

interface Props {
  actions: { title: string; counter: number; createLink?: string; viewLink: string }[];
}

const ViewLocationComponent = ({ actions }: Props): React.ReactElement => {
  const navigate = useNavigate();

  const handleAction = (link: string) => {
    navigate(link);
  };

  return (
    <Grid container spacing={4}>
      {actions.map((action) => (
        <GridCard
          key={action.title}
          title={action.title}
          counter={action.counter}
          editLabel="Create"
          handleEdit={action.createLink ? () => handleAction(action.createLink!) : undefined}
          handleView={() => handleAction(action.viewLink)}
        />
      ))}
    </Grid>
  );
};

export default ViewLocationComponent;

import { ValidatorsUtils, Types } from '@blumtechgroup/blum-ui-utils';
type ValidationType = Types.ValidationType;

export interface PasswordReset {
  email: string;
  password: string;
  passwordConfirm: string;
  verificationCode: string;
}

export interface ValidationErrors {
  email: ValidationType;
  validEmail: ValidationType;
  password: ValidationType;
  passwordConfirm: ValidationType;
  passwordMatch: ValidationType;
  verificationCode: ValidationType;
  verificationCodeLength: ValidationType;
  reset: ValidationType;
}

export const validationErrors: ValidationErrors = {
  email: 'Please provide an email address',
  validEmail: 'Please provide a valid email address',
  password: 'Please provide a new password',
  passwordConfirm: 'Please confirm the password',
  passwordMatch: 'Passwords must match',
  verificationCode: 'Please provide a verification code',
  verificationCodeLength: 'Please provide an verification code with 6 digits',
  reset: '',
};

export interface Validation {
  email: (value: string) => ValidationType;
  password: (value: string, passwordConfirm: string) => ValidationType;
  passwordConfirm: (value: string, newPassword: string) => ValidationType;
  verificationCode: (value: string) => ValidationType;
  reset: (errorMessage: string) => ValidationType;
}

export const validation: Validation = {
  email: (email) => {
    if (!email) return validationErrors.email;
    if (!ValidatorsUtils.validateEmailAddress(email)) return validationErrors.validEmail;
    return false;
  },
  password: (password, passwordConfirm) => {
    if (!password) return validationErrors.password;
    if (passwordConfirm && password !== passwordConfirm) return validationErrors.passwordMatch;
    return false;
  },
  passwordConfirm: (passwordConfirm, newPassword) => {
    if (!passwordConfirm) return validationErrors.password;
    if (newPassword && passwordConfirm !== newPassword) return validationErrors.passwordMatch;
    return false;
  },
  verificationCode: (verificationCode) => {
    if (!verificationCode) return validationErrors.verificationCode;
    if (verificationCode.length !== 6) return validationErrors.verificationCodeLength;
    return false;
  },
  reset: (errorMessage) => (!errorMessage ? errorMessage : false),
};

export enum PasswordResetReducerAction {
  EMAIL,
  PASSWORD,
  PASSWORD_CONFIRM,
  VERIFICATION_CODE,
}

export const passwordResetReducer = (state: PasswordReset, action: { type: PasswordResetReducerAction; value: any }): PasswordReset => {
  switch (action.type) {
    case PasswordResetReducerAction.EMAIL:
      return { ...state, email: action.value.toLowerCase() };
    case PasswordResetReducerAction.PASSWORD:
      return { ...state, password: action.value };
    case PasswordResetReducerAction.PASSWORD_CONFIRM:
      return { ...state, passwordConfirm: action.value };
    case PasswordResetReducerAction.VERIFICATION_CODE:
      return { ...state, verificationCode: action.value };
    default:
      throw new Error();
  }
};

import { gql } from '@apollo/client';

export const insertLocationUserMutation = gql`
  mutation ($object: users_location_admin_insert_input!) {
    insert_users_location_admin_one(object: $object) {
      id
    }
  }
`;

export interface InsertLocationUserResponse {
  insert_users_location_admin_one: { id: string };
}
